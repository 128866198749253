import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getFormationById } from "../../../../services/formation.service";
import { CircularProgress, IconButton } from "@mui/material";
import viewIcon from "../../../../assets/viewIcon.png";
import PDFIcon from "../../../../assets/pdfIcon.png";
import VideoIcon from "../../../../assets/videoIcon.png";
import FolderIcon from "../../../../assets/file-icon.png";

const DetailsFormationCoach = () => {
  const { id } = useParams();
  const [formation, setFormation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFormation = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await getFormationById(id);
      setFormation(response);
    } catch (err) {
      setError(
        err.message || "An error occurred while fetching the formation."
      );
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchFormation();
  }, [fetchFormation]);

  const renderMediaIcon = (media) => {
    if (media.url.endsWith(".pdf")) {
      return <img className="w-6 h-8" src={PDFIcon} alt="PDF Icon" />;
    } else if (media.url.endsWith(".mp4")) {
      return <img className="w-6 h-6" src={VideoIcon} alt="Video Icon" />;
    } else if (
      media.url.endsWith(".jpg") ||
      media.url.endsWith(".jpeg") ||
      media.url.endsWith(".png") ||
      media.url.endsWith(".jfif")
    ) {
      return (
        <img
          className="w-6 h-6 object-cover"
          src={media.url}
          alt={media.name}
        />
      );
    } else {
      return <img className="w-8 h-8" src={FolderIcon} alt="Folder Icon" />;
    }
  };

  if (loading) return <CircularProgress size={20} />;
  if (error) return <div></div>;

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-2 text-[#0976BC]">
        {formation?.title}
      </h1>
      <div className="flex flex-wrap gap-2">
        {formation.categories?.map((category, index) => (
          <span
            key={`${formation.id}-${index}`}
            className="text-[#3366CC] text-xs whitespace-nowrap px-[10px] py-[4px] bg-[#699BF736] rounded-full capitalize"
          >
            {category}
          </span>
        ))}
      </div>
      <h1 className="text-2xl font-semibold my-4">Détails formation :</h1>
      <div dangerouslySetInnerHTML={{ __html: formation?.description }} />

      <div className="bg-white p-6 rounded-lg shadow-md border border-gray-300">
        <h3 className="font-semibold text-xl mb-4">Contenu Disponible</h3>
        <ul className="space-y-4">
          {formation?.media.map((resource, index) => (
            <li
              key={index}
              className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-sm hover:shadow-md"
            >
              <div className="flex items-center space-x-3">
                {renderMediaIcon(resource)}
                <div>
                  <p className="text-sm font-medium text-gray-800 mb-0">
                    {resource.name}
                  </p>
                </div>
              </div>
              <a href={resource.url} target="_blank" rel="noopener noreferrer">
                <IconButton
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    border: "1px solid #0976BC",
                    height: "32px",
                    width: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px",
                    margin: "0",
                    cursor: "pointer",
                    transition: "all 0.2s",
                  }}
                >
                  <img className="w-6 h-4" src={viewIcon} alt="View Details" />
                </IconButton>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DetailsFormationCoach;
