import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Box, Button, FormControl, Grid, Paper, Select } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UserSettings from "../../../components/common/UserSettings";

const ReglageFournisseur = () => {
  const [editorData, setEditorData] = useState("");
  const { userData, fetchUserProfile } = useAuth();

  const handleCKEditorChange = (event, editor) => {
    setEditorData(editor.getData());
  };

  return (
    <div className="bg-slate-50">
      <UserSettings userData={userData} fetchUserProfile={fetchUserProfile} />
      <Box className="mx-auto p-6 text-foreground mb-5 bg-slate-50">
        <Paper elevation={3} className="border rounded-lg bg-white overflow-hidden shadow-none">
          {/* Title Section */}
          <Box className="bg-gradient-to-r from-[#c1e0f1] to-[#f9d9db] w-full h-10 flex items-center pl-4 font-semibold">
            Informations Générales
          </Box>

          {/* Content Section */}
          <Box className="p-6">
            <Grid container spacing={3}>
              {/* Left Column */}
              <Grid item xs={12} sm={6}>
                {/* Categories */}
                <Box className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Catégories
                  </label>
                  <FormControl fullWidth>
                    <Select className="h-[56px]"></Select>
                  </FormControl>
                </Box>

                {/* Subcategories */}
                <Box className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Sous Catégories
                  </label>
                  <FormControl fullWidth>
                    <Select className="h-[56px]"></Select>
                  </FormControl>
                </Box>

                {/* Description */}
                <Box className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    onChange={handleCKEditorChange}
                  />
                </Box>
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} sm={6}>
                {/* Catalogue */}
                <Box className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Catalogue
                  </label>
                  <div
                    className="border-dashed border-2 border-gray-300 p-4 text-center rounded-lg"
                    onClick={() =>
                      document.getElementById("media-upload").click()
                    }
                  >
                    <p>📄 Télécharger des fichiers</p>
                    <Button variant="outlined" color="primary" className="mt-2">
                      Parcourir le fichier
                    </Button>
                    <input
                      id="media-upload"
                      type="file"
                      multiple
                      accept=".jpeg, .png, .mp4, .pdf, .doc, .docx"
                      className="hidden"
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>

            {/* Buttons Section */}
            <Box className="mt-4 flex justify-end gap-2">
              <Button
                variant="outlined"
                className="text-[#0976BC] border-[#0976BC] hover:border-[#065A94] hover:bg-[#065A94] hover:text-white transition-colors duration-300 px-[20px] py-[8px] rounded-lg"
                style={{ textTransform: "none" }}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[20px] py-[8px] rounded-lg"
                style={{ textTransform: "none" }}
              >
                Enregistrer
              </Button>
            </Box>
          </Box>
        </Paper>
        <ToastContainer />
      </Box>
    </div>
  );
};

export default ReglageFournisseur;
