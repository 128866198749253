import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import config from "./assets/environments/config.json";

import "./index.css";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import reportWebVitals from "./reportWebVitals";
import router from "./setup/routes";
import ActiveMenuContextProvider from "./contexts/MenuContext";
import AuthContextProvider from "./contexts/AuthContext";
import ErrorBoundary from "./ErrorBoundary";

 const loadGoogleAnalytics = () => {
   setTimeout(() => {
     const script = document.createElement("script");
     script.src = `https://www.googletagmanager.com/gtag/js?id=${config.GOOGLE_ANALYTICS_key}`;
     script.async = true;
     document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", config.GOOGLE_ANALYTICS_key);
    };
  }, 20000);
};

 function observeAndRemoveBanner() {
   const observer = new MutationObserver((mutations) => {
     mutations.forEach((mutation) => {
       if (mutation.type === "childList") {
         const banner = document.querySelector(".svc-creator__banner");
         if (banner) {
           banner.remove();
         }
       }
     });
   });

   observer.observe(document.body, { childList: true, subtree: true });

   const banner = document.querySelector(".svc-creator__banner");
   if (banner) {
     banner.remove();
   }
 }

 //Main App component
const App = () => {
  const reCaptchaKey = config.KEY;

  useEffect(() => {
    loadGoogleAnalytics();
    observeAndRemoveBanner();
  }, []);

  return (
    <QueryClientProvider client={new QueryClient({})}>
      <ErrorBoundary>
        <AuthContextProvider>
          <ActiveMenuContextProvider>
            <GoogleReCaptchaProvider reCaptchaKey={config.KEY}>
              <RouterProvider router={router} />
            </GoogleReCaptchaProvider>
          </ActiveMenuContextProvider>
        </AuthContextProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

// Render the App component
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
