import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { getAllSurveyResponsesExportPdf } from "../../../services/surveyResponse.service";
import { getAllProgramsFilters } from "../../../services/program.service";

const ExportButtonDropdown = ({ Exportstatus }) => {
  const [loadingExport, setLoadingExport] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("");

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const programFilters = await getAllProgramsFilters();
        setPrograms(programFilters);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    fetchPrograms();
  }, []);

  const handleProgramSelect = async (event) => {
    const programId = event.target.value;
    setSelectedProgram(programId);

    if (!programId) return;

    setLoadingExport(true);
    try {
      await getAllSurveyResponsesExportPdf({
        programId,
        status: Exportstatus || undefined,
      });
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <Select
        value={selectedProgram}
        onChange={handleProgramSelect}
        displayEmpty
        sx={{
          backgroundColor: "white",
          color: "#333",
          borderRadius: "4px",
          fontFamily: "Arial, sans-serif",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #dee2e6",
          },
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "30px",
            paddingRight: "2.5rem",
          },
          "& .MuiSelect-icon": {
            display: "none",
          },
        }}
        IconComponent={() =>
          loadingExport ? (
            <CircularProgress size={20} style={{ marginRight: "8px" }} />
          ) : (
            <BsFileEarmarkArrowDown className="mr-2 w-6 h-6" />
          )
        }
      >
        <MenuItem disabled value="">
          Exporter
        </MenuItem>
        {programs.map((program) => (
          <MenuItem key={program.id} value={program.id}>
            {program.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ExportButtonDropdown;
