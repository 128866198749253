import React, { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/french";
import { PlainLight } from "survey-core/themes";
import { useNavigate, useParams } from "react-router-dom";
import { getFormById } from "../../../../services/form.service";
import { getAllPrograms } from "../../../../services/program.service";
import { assignEvaluationFormToProgram } from "../../../../services/surveyResponse.service";
import {
  CircularProgress,
  Modal,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const DetailsFormulaireUnite = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [loadingPrograms, setLoadingPrograms] = useState(false);
  const [assigning, setAssigning] = useState(false);

  const fetchFormDetails = async () => {
    setLoading(true);
    try {
      const data = await getFormById(id);
      setData(data);
      if (data) {
        const form = data?.fields;
        const parsedJson = JSON.parse(form);
        const survey = new Model(parsedJson);
        survey.mode = "display";
        survey.applyTheme(PlainLight);
        survey.locale = "fr";
        survey.onAfterRenderSurvey.add((sender) => {
          sender.getAllQuestions().forEach((question) => {
            if (question.name === "total_score") {
              question.visible = false;
            }
          });
        });

        setSurveyModel(survey);
      }
    } catch (error) {
      console.error("Error fetching survey responses:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFormDetails();
  }, []);

  const handleAssign = async () => {
    setOpenModal(true);
    setLoadingPrograms(true);
    try {
      const programResponse = await getAllPrograms();
      setPrograms(programResponse.data);
    } catch (error) {
      console.error("Error fetching programs:", error);
    } finally {
      setLoadingPrograms(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmAssign = async (programId) => {
    setAssigning(true);
    try {
      await assignEvaluationFormToProgram(id, programId);
      setOpenModal(false);
      toast.success("Formulaire assigné avec succès !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      toast.error("Erreur lors de l'assignation du formulaire.");
    } finally {
      setAssigning(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="text-center p-4">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="mx-auto px-6 py-4 bg-card text-foreground bg-slate-50">
            <div className="flex justify-between p-2">
              <h5>Détails formulaire ID: {id} </h5>
              <div className="flex gap-2">
                <button
                  onClick={() => navigate("update")}
                  className={`px-4 py-2 mb-2 rounded-lg ${
                    data?.status === "Publié"
                      ? "bg-gray-100 text-gray-300"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                  disabled={data?.status === "Publié"}
                >
                  Modifier
                </button>
                {data?.formType === "evaluation_approfondie" && (
                  <button
                    onClick={handleAssign}
                    className={`px-4 py-2 mb-2 rounded-lg ${
                      data?.isAssigned
                        ? "bg-gray-100 text-gray-300 cursor-not-allowed"
                        : "bg-blue-500 text-white hover:bg-blue-600"
                    }`}
                    disabled={data?.isAssigned}
                  >
                    Assigner
                  </button>
                )}
              </div>
            </div>
            <div>{surveyModel && <Survey model={surveyModel} />}</div>
          </div>
          <div className="flex justify-start p-2 m-2">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Retour à la page d'accueil
            </button>
          </div>
          <ToastContainer />
        </div>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            height: 500,
            overflow: "hidden",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            Assigner un appel à candidature
          </Typography>

          {loadingPrograms ? (
            <div className="text-center p-4">
              <CircularProgress />
            </div>
          ) : (
            <Box
              sx={{
                maxHeight: 380,
                overflowY: "auto",
                mt: 2,
                p: 1,
                borderRadius: "4px",
              }}
            >
              {programs.length > 0 ? (
                programs.map((program) => (
                  <Box
                    key={program.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 2,
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <Typography>{program.title}</Typography>
                    <Button
                      color="primary"
                      onClick={() => handleConfirmAssign(program?.id)}
                      disabled={assigning}
                      className="bg-blue-500 text-white hover:bg-blue-600 px-4 py-2 rounded-lg"
                    >
                      {assigning ? "Assignation..." : "Assigner"}
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography sx={{ textAlign: "center" }}>
                  Aucun programme disponible.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DetailsFormulaireUnite;
