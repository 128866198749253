import React from 'react';
import CheckCircleIcon from '../assets/check_circle.svg';
import TimeProgressIcon from '../assets/lets-icons_time-progress-duotone.svg';
import eligibleIcon from '../assets/Vector.png'
import CancelIcon from '../assets/cancel.svg';

const GetStatus = ({ status }) => {
  let color, StatusIcon;

  switch (status) {
    case 'Éligible':
      color = "text-[#091E42]";
      StatusIcon = eligibleIcon;
      break;
    case "Rejeté":
      color = "text-[#091E42]";
      StatusIcon = CancelIcon;
      break;
    case "SOUMIS":
      color = "text-[#091E42]";
      StatusIcon = CheckCircleIcon;
      break;
    case "À Évaluer":
      color = "text-[#091E42]";
      StatusIcon = TimeProgressIcon;
      break;
    case "Brouillon":
      color = "text-[#091E42]";
      StatusIcon = TimeProgressIcon;
      break;
    default:
      color = "text-[#091E42]";
      StatusIcon = CheckCircleIcon;
      break;
  }

  return (
    <div className="flex gap-[.5px] items-center ">
      {StatusIcon && (
        <img src={StatusIcon} alt={status} className="w-6 h-6 mr-1" />
      )}
      <span className={color}>{status?.toLowerCase()}</span>
    </div>
  );
};

export default GetStatus;
