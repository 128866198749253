import React, { useContext, useEffect, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model, Serializer } from "survey-core";
import "survey-core/i18n/french";
import { sendSurveyResponse } from "../services/surveyResponse.service";
import { getProgramToFill } from "../services/program.service";
import { uploadFile } from "../services/uploadFile.service";
import Stepper from "./Stepper";
import "survey-core/defaultV2.min.css";
import { PlainLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import LaureatGetSurveyForm from "../pages/Laureat-Dashboard/LaureatGetSurveyForm";
import { CircularProgress, Button } from "@mui/material";
import ClosedProgramErrorMessage from "../components/program/ClosedProgramErrorMessage";
import ErrorBoundary from "../ErrorBoundary";
import { SurveyPDF } from "survey-pdf";
const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.6)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999,
};

const blurredStyle = {
  filter: "blur(3px)",
  pointerEvents: "none", 
  userSelect: "none",  
};




const LaureatGetProgramSurvey = () => {
  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorData, setErrorData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const customNavItemRef = useRef(null);

  const navigate = useNavigate();
  const { userId } = useContext(AuthContext);
  useEffect(() => {
    Serializer.addProperty("itemvalue", { name: "score:number" });
  }, []);

  useEffect(() => {
    const programId = localStorage.getItem("programId");

    if (!programId || !userId) {
      return;
    }

    const fetchProgramToFill = async () => {
      try {
        const program = await getProgramToFill(programId, userId);

        const formFields =
          program?.form?.fields || program?.program?.form?.fields;

        if (formFields) {
          const parsedSurvey = JSON.parse(formFields);

          const stepsTitles = parsedSurvey.pages.map((page) => ({
            title: page.title,
            name: page.name,
          }));

          stepsTitles.push({ title: "", name: "" });

          const survey = new Model(parsedSurvey);
          survey.applyTheme(PlainLight);
          survey.showPreviewBeforeComplete = "showAnsweredQuestions";
          survey.locale = "fr";
          survey.completeText = "Soumettre";
          survey.previewText = "Aperçu et soumettre"

          survey.getAllQuestions().forEach((question) => {
            if (question.getType() === "file") {
              question.storeDataAsText = false;
              question.allowImagesPreview = true;
              question.waitForUpload = true;
              question.allowMultiple = true;
            }
          });
          const storageKey = `surveyData_${programId}_${userId}`;
          const savedData = localStorage.getItem(storageKey);
          if (savedData) {
            survey.data = JSON.parse(savedData);
          } else if (program?.surveyResponse?.status === "Brouillon") {
            const oldResponses = program.surveyResponse.responses;

            if (oldResponses) {
              survey.data = oldResponses;
            }
          }
          survey.onUploadFiles.add(async (_, options) => {
            const uploadedUrls = [];
            for (const file of options.files) {
              const urls = await uploadFile(
                file,
                "laureat/" + programId + "/" + userId,
                18000,
                "private"
              );

              uploadedUrls.push(
                Object.assign({}, file, {
                  name: urls[0].name,
                  fullUrl: urls[0].url,
                  type: file.type,
                  size: file.size,
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                  webkitRelativePath: file.webkitRelativePath,
                })
              );
            }
            options.files = uploadedUrls;

            options.callback(
              options.files.map((file) => {
                return {
                  file: file,
                  content: file.fullUrl,
                };
              })
            );
          });

          survey.onValueChanged.add((sender, options) => {
            const surveyData = sender.data;
            localStorage.setItem(storageKey, JSON.stringify(surveyData));
          });

          survey.onCurrentPageChanged?.add((sender) => {
            const newIndex = stepsTitles.findIndex(
              (step) => step.name === sender.currentPage.name
            );
            setIsFinalStep(newIndex + 1 === stepsTitles.length);
            setCurrentStep(newIndex);
          });

          const navItem =  survey.addNavigationItem({
            id: "survey_save_current_page",
            title: "Enregistrer",
            css: isRequestProcessing ? "sv_nav_btn disabled" : "sv_nav_btn",
            visible: true,
            enabled: !isRequestProcessing,

            action: async () => {
              if (isRequestProcessing) return;
              setIsRequestProcessing(true);

              const surveyData = survey.data;
              const token = localStorage.getItem("access-token");
              const responseData = {
                programId: parseInt(programId),
                responses: surveyData,
                action: "enregistrer",
                user_id: userId,
              };
              try {
                await sendSurveyResponse(token, responseData);
                localStorage.removeItem(storageKey);
                toast.success(
                  "Vos réponses ont été enregistrées avec succès en tant que brouillon!",
                  {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "colored",
                    transition: Bounce,
                  }
                );

                setTimeout(() => {
                  navigate("/laureat-dashboard");
                  setIsRequestProcessing(false);
                }, 2000);
              } catch (error) {
                console.error("Error saving the draft:", error);
                setIsRequestProcessing(false);

                toast.error(
                  "Erreur lors de l'enregistrement du brouillon. Veuillez réessayer.",
                  {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "colored",
                    transition: Bounce,
                  }
                );
              }
            },
          });
          customNavItemRef.current = navItem;

          function calculateMaxScore(questions) {
            let maxScore = 0;

            questions.forEach((question) => {
              if (question.choices) {
                if (
                  question.getType() === "checkbox" ||
                  question.getType() === "tagbox"
                ) {
                  const totalCheckboxScore = question.choices.reduce(
                    (sum, choice) => sum + (choice.score || 0),
                    0
                  );

                  maxScore += totalCheckboxScore;
                } else {
                  const maxValue = Math.max.apply(
                    Math,
                    question.choices.map((choice) => choice.score || 0)
                  );

                  maxScore += maxValue;
                }
              }

              // For rating scale questions (rateValues)
              if (question.rateValues) {
                const maxValue = Math.max.apply(
                  Math,
                  question.rateValues.map((rate) => rate.score || 0)
                );

                maxScore += maxValue;
              }

              // For matrix questions
              if (question.getType() === "matrix") {
                const maxMatrixValue =
                  Math.max.apply(
                    Math,
                    question.columns.map((column) => column.score || 0)
                  ) * question.rows.length;
                maxScore += maxMatrixValue;
              }
            });

            return maxScore;
          }
          function calculateTotalScore(data) {
            let totalScore = 0;

            data.forEach((item) => {
              const question = survey.getQuestionByValueName(item.name);
              const qValue = item.value;

              // For questions with choices (radio buttons or checkboxes)
              if (question?.choices) {
                if (Array.isArray(qValue)) {
                  // Checkbox (multiple selections)
                  qValue.forEach((selectedValue) => {
                    const selectedChoice = question.choices.find(
                      (choice) => choice.value === selectedValue
                    );
                    if (selectedChoice) {
                      totalScore += selectedChoice.score || 0;
                    }
                  });
                } else {
                  // Radio button (single selection)
                  const selectedChoice = question.choices.find(
                    (choice) => choice.value === qValue
                  );
                  if (selectedChoice) {
                    totalScore += selectedChoice.score || 0;
                  }
                }
              }

              // For rating scale questions
              if (question?.rateValues) {
                const selectedRate = question.rateValues.find(
                  (rate) => rate.value === qValue
                );
                if (selectedRate) {
                  totalScore += selectedRate.score || 0;
                }
              }

              // For matrix questions
              if (question.getType() === "matrix" && item.data) {
                item.data.forEach((dataItem) => {
                  if (dataItem?.value && dataItem.score) {
                    totalScore += dataItem.score;
                  }
                });
              }
            });

            return totalScore;
          }

          survey.onComplete?.add(async (sender) => {
            // const totalScore = sender.getValue("totalScore");
            if (isRequestProcessing) return;
            setIsRequestProcessing(true);

            const maxScore = calculateMaxScore(sender?.getAllQuestions());
            const plainData = sender?.getPlainData({
              calculations: [{ propertyName: "score" }],
            });
            const totalScore = calculateTotalScore(plainData);

            sender.setValue("maxScore", maxScore);
            sender.setValue("totalScore", totalScore);
            const surveyData = sender.data;

            const token = localStorage.getItem("access-token");
            const responseData = {
              programId: parseInt(programId),
              responses: surveyData,
              user_id: userId,
              note: totalScore,
              totalNote: maxScore,
              action: "envoyer",
            };
            try {
              await sendSurveyResponse(token, responseData);
              localStorage.removeItem(storageKey);
              toast.success("Vos réponses ont été envoyées avec succès!", {
                position: "bottom-right",
                autoClose: 2000,
                theme: "colored",
                transition: Bounce,
              });

              setTimeout(() => {
                navigate("/laureat-dashboard");
                setIsRequestProcessing(false);
              }, 2000);
            } catch (error) {
              console.error("Error sending final response:", error);
              setIsRequestProcessing(false);
              toast.error(
                "Erreur lors de l'envoi des réponses. Veuillez réessayer.",
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  theme: "colored",
                  transition: Bounce,
                }
              );
              
            }
          });

          setSteps(stepsTitles);
          setSurveyModel(survey);
        } else {
          setErrorData("No survey data found for this program.");
        }
      } catch (err) {
        // console.error("Failed to fetch the program to fill. Please try again later.", err);
        // setErrorData("Failed to fetch the program to fill. Please try again later.");

        console.error("Error caught in LaureatGetProgramSurvey:", err.message);

        if (err.message.includes("Fermé") || err.message.includes("closed")) {
          setErrorData("closedProgram");
        } else {
          setErrorData(
            "Failed to fetch the program to fill. Please try again later."
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProgramToFill();
  }, [userId]);

  useEffect(() => {
    if (customNavItemRef.current) {
      customNavItemRef.current.enabled = !isRequestProcessing;
      surveyModel.showNavigationButtons = !isRequestProcessing;
  
    }
  
    if (surveyModel) {
  
      surveyModel.showPreviewBeforeComplete = !isRequestProcessing
        ? "showAnsweredQuestions"
        : "none";
    }
  }, [isRequestProcessing, surveyModel]);
  

  const handleExportPDF = () => {
    if (surveyModel) {
      const pdfSurvey = new SurveyPDF(surveyModel.toJSON());
  
      pdfSurvey.onRenderHeader.add(async (_, canvas) => {
        try {
          const base64Image =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAusAAAExCAIAAABK1nu+AAAACXBIWXMAABJ0AAASdAHeZh94AAAAB3RJTUUH5wQFDisClytmJwAAIABJREFUeJzt3V1MXGee5/GTnS7Y1KqqtO3jEagAycVqzYsjgi8IFWTJkl8qcaRtOTKObzaWAtmb7Rvw3eyF8d7sleGiR3vRMZGSKwesuGdXcTd+kdhlSRGkMU3bvGkElqBKoPGxNFU1qhbUdPdenEk1bQw8z1Pn7Sm+n6tMD1V1THHO+Z3/8/yf563GX/3CAAAA0Mq/8fsAAAAApJFgAACAfkgwAABAPyQYAACgHxIMAADQDwkGAADohwQDAAD0Q4IBAAD6IcEAAAD9kGAAAIB+SDAAAEA/JBgAAKAfEgwAANAPCQYAAOiHBAMAAPRDggEAAPohwQAAAP2QYAAAgH5IMAAAQD8kGAAAoB8SDAAA0A8JBgAA6IcEAwAA9EOCAQAA+iHBAAAA/ZBgAACAfkgwAABAPyQYAACgHxIMAADQDwkGAADohwQDAAD0Q4IBAAD6IcEAAAD9kGAAAIB+SDAAAEA/JBgAAKAfEgwAANAPCQYAAOiHBAMAAPRDggEAAPohwQAAAP2QYAAAgH5IMAAAQD8kGAAAoB8SDAAA0A8JBgAA6IcEAwAA9EOCAQAA+iHBAAAA/ZBgAACAfkgwAABAPyQYAACgHxIMAADQDwkGAADohwQDAAD0Q4IBAAD6IcEAAAD9kGAAAIB+SDAAAEA/JBgAAKAfEgwAANAPCQYAAOiHBAMAAPRDggEAAPohwQAAAP2QYAAAgH7e+v5lxu9jAAAAihZz1q3nU34fhQ/e+tOf/uT3MQAAAHULOevq9LeF0o7fB+IpRpEAANBbe8wc6/k4Ho74fSCeogYDAEA1yJe2r07fX8xZfh+IR6jBAABQDaKh2rGeyxfrTvh9IB6hBgMAQFUZfPr43say30fhOhIMAADVZnR1vuoblEgwAABUofH1pRtzT/w+CheRYAAAqE7V3WXNTF4AAKpTdXdZU4MBAKCaVWuXNTUYAACqWbV2WVODAQDgSKiyLmsSDAAAR0U1dVmTYAAAOEKqpsuaBAMAwNFSHV3WzOQFAOBoqY4ua2owAAAcRbp3WVODAQDgKNK9y5oaDAAAR5qmXdYkGAAAjjodu6xJMAAAQL8uaxIMAAAwDN26rJnJCwAADEO3LmtqMAAA4M906bKmBgMAAP5Mly5rajAAAOANAt5lTYIBAABvFuQuaxIMAADYV2C7rEkwAADgIMHssmYmLwAAOEgwu6ypwQAAgMMFrcuaGgwAADhc0LqsqcEAAAAJAemyJsEAAAA5QeiyJsEAAABpvndZk2AAAIAKf7usmckLAABU+NtlTQ0GAACo86vLmhoMAABQ51eXNTUYAADgAI+7rEkwAADAGV52WZNgAACAYzzrsibBAAAAJ3nTZc1MXgAA4CRvuqypwQAAAOe53WVNDQYAADjP7S5rajAAAMBFLnVZk2AAAIC73OiyJsEAAADXOd5lTYIBAABecLbLmpm8AADAC852WVODAQAA3nGqy5oaDAAA8I5TXdbUYAAAgA8q7LImwQAAAH9U0mVNggEAAL5R7rImwQAAAD+pdVkzkxcAAPhJrcuaGgwAAPCfbJc1NRgAAOA/2S5rajAAACBABLusSTAAACBYRLqsSTAAACBwDu2yJsEAAIAgOrjLmpm8AAAgiA7usqYGAwAAgmu/LmtqMAAAILj267KmBgMAADTwWpc1CQYAAOhhd5c1CQYAAGij3GVNggEAADqxu6xJMAAAQDMLOYsEAwAA9PP/AQdCrmHwiGzsAAAAAElFTkSuQmCC";

          const headerHeight = 60; 
          const canvasWidth = canvas.rect.xRight - canvas.rect.xLeft;

          canvas.rect.yTop -= headerHeight; 
 
          await canvas.drawImage({
            base64: base64Image,
            x: canvas.rect.xLeft, 
            y: canvas.rect.yTop,
            width: canvasWidth,
            height: headerHeight,
          });
        } catch (error) {
          console.error("Error rendering canvas header:", error);
        }
      });
  
      pdfSurvey.save("Formulaire.pdf");
    }
  }; 

  if (loading) return <CircularProgress size={24} />;

  if (errorData) {
    return (
      <div>
        {errorData === "closedProgram" ? (
          <ClosedProgramErrorMessage />
        ) : (
          <LaureatGetSurveyForm />
        )}
      </div>
    );
  }

  return (
    <div className="App">
      {/* (1) Show overlay & loader if isRequestProcessing is true */}

      {isRequestProcessing && (
        <div style={overlayStyle}>
          <CircularProgress size={60} />
        </div>
      )}

      {/* (2) Apply blurred style when isRequestProcessing is true */}
      <div style={isRequestProcessing ? blurredStyle : {}}>
        {/* Show Stepper if steps exist */}
        {steps.length > 0 && (
          <Stepper
            steps={steps}
            currentStep={isFinalStep ? steps.length : currentStep}
          />
        )}

        <div style={{ marginTop: "20px", textAlign: "right", marginRight: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportPDF}
            style={{ textTransform: "none" }}
          >
            Télécharger en PDF
          </Button>
        </div>

        {loading ? (
          <CircularProgress size={24} />
        ) : errorData ? (
          <div>
            {errorData === "closedProgram" ? (
              <ClosedProgramErrorMessage />
            ) : (
              <LaureatGetSurveyForm />
            )}
          </div>
        ) : (
          <ErrorBoundary>
            {surveyModel ? (
              <Survey model={surveyModel} />
            ) : (
              <LaureatGetSurveyForm />
            )}
          </ErrorBoundary>
        )}
      </div>

      <ToastContainer />
    </div>
  );

};

export default LaureatGetProgramSurvey;
