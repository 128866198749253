import React, { useState } from "react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { BsPlusSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
// import CircularProgress from "@mui/material/CircularProgress";
// import debounce from "lodash.debounce";
// import { AuthContext } from "../../../contexts/AuthContext";

const ListeAchatsLaureat = () => {

  const navigate = useNavigate();

  // const coachAssignmentOptions = [
  //   { value: "", label: "Tout" },
  //   { value: "Rejeté", label: "Rejeté" },
  //   { value: "À Évaluer", label: "À Évaluer" },
  //   { value: "Sélectionné", label: "Sélectionné" },
  //   { value: "Retenue", label: "Retenue" },
  // ];

  // const fetchCandidatures = useCallback(async () => {
  //   if (!userId) {
  //     console.error("User ID is required to fetch candidatures.");
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const response = await getSurveyResponsesForEvaluator(userId, {
  //       page: page + 1,
  //       limit: rowsPerPage,
  //       searchTerm,
  //       programId: selectedProgram?.id,
  //       status: coachAssignmentStatus,
  //     });

  //     setCandidatures(response.data);
  //     setTotalCount(response.totalDocs);
  //   } catch (error) {
  //     console.error("Error fetching candidatures:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [
  //   userId,
  //   page,
  //   rowsPerPage,
  //   searchTerm,
  //   coachAssignmentStatus,
  //   selectedProgram,
  // ]);

  // useEffect(() => {
  //   if (userId) {
  //     fetchCandidatures();
  //   }
  // }, [fetchCandidatures, userId]);

  // useEffect(() => {
  //   const fetchProgramFilters = async () => {
  //     try {
  //       const programFilters = await getAllProgramsFilters();
  //       programFilters?.unshift({
  //         id: undefined,
  //         title: "Appels à candidature",
  //       });
  //       setPrograms(programFilters);
  //     } catch (error) {
  //       console.log("error in fetching program filters list", error);
  //     }
  //   };

  //   fetchProgramFilters();
  // }, []);

  // const debouncedSearch = useCallback(
  //   debounce((value) => {
  //     setSearchTerm(value);
  //     setPage(0);
  //   }, 500),
  //   []
  // );

  // const handleSearchChange = (event) => debouncedSearch(event.target.value);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("fr-FR", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   });
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleProgramChange = (event) => {
  //   const selectedValue = JSON.parse(event.target.value);
  //   setSelectedProgram(selectedValue);
  // };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClickAddDevis = () => {
    navigate("/laureat-dashboard/achat/create");
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Gestion d'achats</h1>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités d'achats.
      </p>
      <div className="flex justify-end mb-4">
        <Button
          style={{ textTransform: "none" }}
          className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center  font-medium"
          startIcon={<BsPlusSquare />}
          onClick={handleClickAddDevis}
        >
          Nouvelle demande de devis
        </Button>
      </div>
      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom du candidat, Email,..."
              // onChange={handleSearchChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <FormControl variant="outlined" sx={{ width: 200 }}>
              <Select
                // value={coachAssignmentStatus}
                //   onChange={(event) => {
                //     setCoachAssignmentStatus(event.target.value);
                //     setPage(0);
                //   }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
                )}
                //   renderValue={(selected) => {
                //     if (selected === "") {
                //       return "Tout les status";
                //     }
                //     const selectedOption = coachAssignmentOptions.find(
                //       (option) => option.value === selected
                //     );
                //     return selectedOption
                //       ? selectedOption.label
                //       : "Tout les status";
                //   }}
              >
                {/* {coachAssignmentOptions.map((option) => ( */}
                <MenuItem
                // key={option.value}
                // value={option.value}
                >
                  {/* {option.label} */}
                </MenuItem>
                {/* ))} */}
              </Select>
            </FormControl>
          </div>
        </div>
        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          <Table>
            <TableHead>
              <TableRow hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    //   checked={
                    //     selected.length > 0 &&
                    //     selected.length === candidatures.length
                    //   }
                    inputProps={{ "aria-label": "select all candidatures" }}
                  />
                </TableCell>
                <TableCell>Nom de la demande</TableCell>
                <TableCell>Nom du fournisseur</TableCell>
                <TableCell>Date de soumission</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />{" "}
                    </TableCell>
                  </TableRow>
                ) : candidatures.length > 0 ? (
                  candidatures.map((candidature) => ( */}
              <TableRow
                hover
                role="checkbox"
                //   aria-checked={isSelected(candidature?.id)}
                tabIndex={-1}
                //   key={candidature.id}
                //   selected={isSelected(candidature?.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                  // disabled={!!candidature?.keycloakCoachId}
                  //   checked={isSelected(candidature?.id)}
                  //   inputProps={{ "aria-labelledby": candidature?.id }}
                  />
                </TableCell>
                <TableCell>
                  {/* {candidature.user?.firstName} {candidature.user?.lastName}{" "} */}
                </TableCell>
                <TableCell>{/* {candidature.user?.email} */}</TableCell>

                <TableCell>
                  {/* <GetStatusLaureat status={candidature?.status} /> */}
                </TableCell>
                <TableCell>
                  {/* <Link
                          to={`/evaluateur-dashboard/candidatures/${candidature?.id}`}
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                          style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                        >
                          {candidature?.status === "Rejeté" ||
                          candidature?.status === "Sélectionné" ||
                          candidature?.status === "Retenue" ? (
                            <span>Voir détails</span>
                          ) : (
                            <span>Evaluation</span>
                          )}
                        </Link> */}
                </TableCell>
              </TableRow>
              {/* ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Aucune donnée affichée
                    </TableCell>
                  </TableRow>
                )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        /> */}
      </div>
    </div>
  );
};

export default ListeAchatsLaureat;
