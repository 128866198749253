import React from "react";

const DetailsAchat = () => {
  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Détails demande de devis</h1>
      <p className="text-gray-500 text-sm mb-2">
        Visualisez les demandes de devis soumises par les lauréats. Validez-les
        pour les envoyer aux fournisseurs ou demandez des modifications.
      </p>
      <div className="border rounded-lg p-6 bg-white">
        <div>
          <h2 className="text-lg font-semibold">Nom de la demande :</h2>
          <p className="mb-3">Achat de matériel informatique pour bureau</p>

          <h2 className="text-lg font-semibold">Description des besoins :</h2>
          <p className="mb-3">
            Nous avons besoin de matériel informatique pour équiper un nouveau
            bureau. Les produits doivent être de haute qualité et livrés dans un
            délai raisonnable.
          </p>

          <h2 className="text-lg font-semibold">Matériel requis :</h2>
          <ul className="list-disc ml-5 mb-3">
            <li>10 ordinateurs portables</li>
            <li>2 imprimantes multifonctions</li>
            <li>10 claviers et souris sans fil</li>
            <li>2 routeurs réseau</li>
          </ul>

          <p className="mb-3">
            <strong>Budget estimé :</strong> 15 000 USD
          </p>
          <p className="mb-6">
            <strong>Date limite de réception des devis :</strong> 15 décembre
            2024
          </p>

          <h2 className="text-lg font-semibold">Fournisseurs sélectionnés :</h2>
          <ul className="list-none ml-0 mb-6">
            <li className="mb-4">
              <strong>Fournisseur A :</strong>
              <ul className="list-none ml-4">
                <li>Nom : Informatique Kinshasa</li>
                <li>
                  Contact :{" "}
                  <a
                    href="mailto:email@informatique-kin.cd"
                    className="text-blue-500 underline"
                  >
                    email@informatique-kin.cd
                  </a>{" "}
                  / +243 81 234 5678
                </li>
                <li>
                  Secteur d'activité : Matériel informatique et électronique
                </li>
              </ul>
            </li>
            <li className="mb-4">
              <strong>Fournisseur A :</strong>
              <ul className="list-none ml-4">
                <li>Nom : Informatique Kinshasa</li>
                <li>
                  Contact :{" "}
                  <a
                    href="mailto:email@informatique-kin.cd"
                    className="text-blue-500 underline"
                  >
                    email@informatique-kin.cd
                  </a>{" "}
                  / +243 81 234 5678
                </li>
                <li>
                  Secteur d'activité : Matériel informatique et électronique
                </li>
              </ul>
            </li>
            <li className="mb-4">
              <strong>Fournisseur A :</strong>
              <ul className="list-none ml-4">
                <li>Nom : Informatique Kinshasa</li>
                <li>
                  Contact :{" "}
                  <a
                    href="mailto:email@informatique-kin.cd"
                    className="text-blue-500 underline"
                  >
                    email@informatique-kin.cd
                  </a>{" "}
                  / +243 81 234 5678
                </li>
                <li>
                  Secteur d'activité : Matériel informatique et électronique
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="flex flex-col justify-start p-4 gap-2 bg-[#E2F1FA] my-4 rounded-md">
          <label
            for="myTextarea"
            className="font-semibold text-[#0976BC] text-[28px] "
          >
            Commentaire du coach:
          </label>
          <textarea
            id="myTextarea"
            rows="4"
            cols="50"
            placeholder=""
            className="p-2 rounded-md"
          ></textarea>
        </div>
        <div className="flex gap-2 justify-end">
          <button className="text-[#0976BC] border border-[#0976BC96] rounded-[20px] py-2 px-4 hover:bg-red-500 hover:text-white transition-colors">
            Rejeter
          </button>
          <button className="text-[#0976BC] border border-[#0976BC96] rounded-[20px] py-2 px-4 hover:bg-green-500 hover:text-white transition-colors">
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailsAchat;
