import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import "survey-creator-core/i18n/french";
import { Serializer } from "survey-core";
import { createForm } from "../services/form.service";
import { Bounce, toast, ToastContainer } from "react-toastify";

const SurveyCreatorWidget = () => {
  const navigate = useNavigate();
  const [formType, setFormType] = useState(""); // Initialize as empty for rendering placeholder

  Serializer.addProperty("itemvalue", {
    name: "score:number",
  });

  const optionsDuCréateur = {
    showLogicTab: false,
    showTranslationTab: false,
    showJSONEditorTab: false,
    isAutoSave: true,
  };

  const jsonParDéfaut = {
    logoPosition: "right",
  };

  const créateur = new SurveyCreator(optionsDuCréateur);
  créateur.locale = "fr";
  créateur.text = JSON.stringify(jsonParDéfaut);

  const createJurForm = async () => {
    try {
      const jsonSondage = créateur.JSON;

      const donnéesFormulaire = {
        fields: JSON.stringify(jsonSondage),
        formType: formType || "appel_a_candidature", // Pass "appel_a_candidature" if no selection is made
      };

      const réponse = await createForm(donnéesFormulaire);
      if (réponse) {
        toast.success("Formulaire a été créé avec succès.", {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        });
        setTimeout(() => {
          navigate("/unite_de_gestion-dashboard/formulaires", {
            state: { formCreated: true },
          });
        }, 2000);
      }
    } catch (error) {
      if (error.response?.data?.message === "Fields must have a title.") {
        toast.error(
          "Le titre est requis. Veuillez spécifier un titre pour votre formulaire.",
          {
            position: "bottom-right",
            autoClose: 3000,
            theme: "colored",
            transition: Bounce,
          }
        );
      }
      console.error("Échec de la sauvegarde du sondage:", error);
    }
  };

  créateur.saveSurveyFunc = (saveNo, callback) => {
    window.localStorage.setItem("json-sondage", créateur.text);
    callback(saveNo, true);
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Créer un nouveau formulaire</h1>

      <FormControl variant="outlined" sx={{ width: 250, marginTop: "20px" }}>
        <Select
          value={formType} 
          onChange={(e) => setFormType(e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={() => (
            <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
          )}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <i className="bi bi-filter text-xl mx-1"></i>
                  <span style={{ flex: 1, textAlign: "center" }}>
                    Type de formulaire
                  </span>
                </div>
              );
            }
            const label = {
              appel_a_candidature: "appel à candidature",
              document_annexe: "document annexe",
              document_cadrage: "document cadrage",
              evaluation_approfondie: "formulaire d'évaluation",
            }[selected];

            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <i className="bi bi-filter text-xl mx-1"></i>
                <span style={{ flex: 1, textAlign: "center" }}>{label}</span>
              </div>
            );
          }}
          sx={{
            backgroundColor: "white",
            color: "#333",
            borderRadius: "4px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #dee2e6",
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "30px",
              paddingRight: "2.5rem",
            },
            "& .MuiSelect-icon": {
              display: "none",
            },
          }}
        >
          <MenuItem value="appel_a_candidature">appel à candidature</MenuItem>
          <MenuItem value="document_annexe">document annexe</MenuItem>
          <MenuItem value="document_cadrage">document cadrage</MenuItem>
          <MenuItem value="evaluation_approfondie">formulaire d'évaluation</MenuItem>
        </Select>
      </FormControl>

      <div className="p-8 bg-white shadow-md rounded-lg mt-2">
        <div className="row">
          <SurveyCreatorComponent creator={créateur} />
        </div>
      </div>

      <div className="flex space-x-4 mt-4 flex-row-reverse gap-8">
        <button
          className="bg-[#0976BC] text-white rounded-[8px] py-3 px-9"
          onClick={createJurForm}
        >
          Sauvegarder
        </button>
        <button
          className="bg-gray-500 text-white rounded-[8px] py-3 px-9"
          onClick={() => navigate(-1)}
        >
          Annuler
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SurveyCreatorWidget;
