import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Model, Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/french";
import { PlainLight } from "survey-core/themes";
import { getSurveyResponseById } from "../../../../services/surveyResponse.service";
import { CircularProgress } from "@mui/material";
import EvaluationProcess from "./EvaluationProcess";
import axiosInstance from "../../../../helpers/axiosIntercepter";
const DetailsCandidaturesEvaluateur = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [surveyModel, setSurveyModel] = useState(null);
  const [evaluationSurveyModel, setEvaluationSurveyModel] = useState(null);
  const navigate = useNavigate();

  const { id } = useParams();

  const questionNamesToBeHidden = [
    "nom_du_candidat",
    "Post_nom_du_candidat",
    "prenom_du_candidat",
    "question4",
    "ville",
    "entreprise_participante",
    "question9",
    "question10",
    "question14",
    "question11",
    "adresse_de_entreprise",
    "question13",
    "question67",
    "question69",
  ];

  const fetchSurveyResponse = async () => {
    setLoading(true);
    try {
      const data = await getSurveyResponseById(id);
      setData(data);
      if (data) {
        const parsedJson = JSON.parse(
          data?.surveyResponse?.program?.form?.fields
        );
        const survey = new Model(parsedJson);
        let mainData = data.surveyResponse.responses;
        mainData = await updatedFilesDate(mainData);
        survey.data = mainData;
        survey.mode = "display";
        survey.applyTheme(PlainLight);
        survey.locale = "fr";
        setSurveyModel(survey);

        const surveyEvaluationJson =
          data?.surveyResponse?.evaluationApprofondie?.fields;
        const evaluationSurvey = new Model(surveyEvaluationJson);
        let evalResponses = data.surveyResponse.evaluationApprofondieResponses;

        evaluationSurvey.data = evalResponses;
        evaluationSurvey.mode = "display";
        evaluationSurvey.applyTheme(PlainLight);
        evaluationSurvey.locale = "fr";
        setSurveyModel(survey);
        setEvaluationSurveyModel(evaluationSurvey);
        // we need exactly the name of the fields to be hidden
        // survey.onGetQuestionTitle.add((sender, options) => {
        //   if (questionNamesToBeHidden.includes(options.question.name)) {
        //     options.question.visible = false; // Hides the question
        //   }
        // });
        survey.onAfterRenderSurvey.add((sender) => {
          sender.getAllQuestions().forEach((question) => {
            if (questionNamesToBeHidden.includes(question.name)) {
              question.visible = false; // ✅ Hides the question completely
            }
          });
        });
        // survey.onGetQuestionTitle.add((sender, options) => {
        //   console.log('options.question.name', options.question.name)
        //   if (options.question.name?.includes('_hide')) {
        //     options.question.visible = false; // Hides email field
        //   }
        // });
      }
    } catch (error) {
      console.error("Error fetching survey responses:", error);
    } finally {
      setLoading(false);
    }
  };

  const updatedFilesDate = async (questions) => {
    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {
      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content);
        if (isExpired) {
          const newUrl = await fetchUpdatedUrl(file.name);
          if (newUrl?.url) {
            updatedObject[key] = [{ ...file, content: newUrl.url }];
          }
        }
      }
    }

    return updatedObject;
  };

  const fetchUpdatedUrl = async (fileName) => {
    try {
      const response = await axiosInstance.get(
        `/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=7200`
      );
      return response.data;
    } catch (error) {
      console.log("Error in updating expired url", error);
      return null;
    }
  };

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const amzDate = urlParams.get("X-Amz-Date");
    const amzExpires = urlParams.get("X-Amz-Expires");

    if (amzDate && amzExpires) {
      const expirationDate = new Date(
        amzDate.slice(0, 4) +
          "-" + // Year
          amzDate.slice(4, 6) +
          "-" + // Month
          amzDate.slice(6, 8) +
          "T" + // Day
          amzDate.slice(9, 11) +
          ":" + // Hour
          amzDate.slice(11, 13) +
          ":" + // Minute
          amzDate.slice(13, 15) +
          "Z" // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      return Date.now() > expirationTime;
    }

    return false;
  };

  useEffect(() => {
    fetchSurveyResponse();
  }, []);

  const systemEval = data?.surveyResponse?.evaluationActions.filter(
    (item) => item?.isSystemEvaluation
  );

  const evaluatorResponse = data?.surveyResponse?.evaluationActions.filter(
    (item) => !item?.isSystemEvaluation
  );

  return (
    <>
      {loading ? (
        <div className="p-4 w-full justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="border rounded-lg p-4 mt-3 bg-white ml-2 ">
          <h1 className="text-3xl font-bold">
            Détails de candidature: <strong> Ref ID </strong>
            {id}
          </h1>
          <div className="space-y-3">
            {/* System evaluation  */}
            {systemEval && systemEval?.length > 0 && (
              <div className="flex flex-col border-2 rounded-lg p-2">
                <h5>Évaluation initiale du systéme:</h5>
                <p>
                  Note : {systemEval[0].note}/{systemEval[0].totalNote}
                </p>
                <p className="">
                  Statut :{" "}
                  <span
                    className={` ${
                      systemEval[0].status === "Rejeté"
                        ? "text-[#EE5D50]"
                        : "text-[#05CD99]"
                    } `}
                  >
                    {systemEval[0].status === "Rejeté"
                      ? "Non éligible"
                      : systemEval[0].status}
                  </span>
                </p>
              </div>
            )}

            {data?.surveyResponse?.evaluationApprofondieResponses && (
              <div className="flex flex-col border-2 rounded-lg p-2">
                <h5>Évaluation approfondie de l'évaluateur:</h5>
                <p>
                  Note :{" "}
                  {
                    data?.surveyResponse?.evaluationApprofondieResponses
                      ?.total_score
                  }
                  %
                </p>
                <p>
                  Commentaire de l'évaluateur :{" "}
                  {evaluatorResponse[0]?.evaluatorComment}
                </p>
                <p className="">
                  Statut :{" "}
                  <span
                    className={` ${
                      evaluatorResponse[0].status === "Rejeté"
                        ? "text-[#EE5D50]"
                        : "text-[#05CD99]"
                    } `}
                  >
                    {data?.surveyResponse.status}
                  </span>
                </p>
              </div>
            )}

            {/* Survey Component (Static Data) */}
            {surveyModel && <Survey model={surveyModel} />}

            {data?.surveyResponse?.evaluationApprofondie &&
              evaluationSurveyModel &&
              data?.surveyResponse?.status !== "À Évaluer" && (
                <Survey model={evaluationSurveyModel} />
              )}
          </div>

          {data?.surveyResponse?.status === "À Évaluer" &&
            data?.surveyResponse?.evaluationApprofondie && (
              <EvaluationProcess
                evaluationForm={
                  data?.surveyResponse?.evaluationApprofondie?.fields
                }
                evaluationFormResponses={
                  data?.surveyResponse?.evaluationApprofondieResponses
                }
                id={id}
                status={data?.surveyResponse?.status}
              />
            )}
          <div className="mt-6 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Retour à la liste des candidatures
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsCandidaturesEvaluateur;
