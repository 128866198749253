import axiosInstance from '../helpers/axiosIntercepter';

export const getSurveyResponses = async (queryParams, coachId) => {
  if (!coachId)return 
  const {
    page,
    limit,
    status,
    conventionStatus,
    searchTerm,
    coachNote,
    startCreatedAt,
    endCreatedAt,
    programId
  } = queryParams;

  const params = new URLSearchParams();

  if (page) params.append('page', page);
  if (limit) params.append('limit', limit);
  if (status) params.append('status', status);
  if (conventionStatus) params.append('conventionStatus', conventionStatus);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (coachNote) params.append('coachNote', coachNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (programId) params.append('programId', programId);

  try {
    const response = await axiosInstance.get(
      `/survey-response/coach/${coachId}`,
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses:', error);
    throw new Error('Failed to fetch survey responses. Please try again later.');
  }
};
export const getSurveyResponsesForEligibleCandidate = async (queryParams = {}) => {
  const {
    page,
    limit,
    searchTerm,
    coachNote,
    startCreatedAt,
    endCreatedAt,
    programId,
    conventionStatus, 
  } = queryParams;

  const params = new URLSearchParams();
  params.append('status', 'Retenue');

  if (page !== undefined) params.append('page', page);
  if (limit !== undefined) params.append('limit', limit);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (coachNote) params.append('coachNote', coachNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (programId) params.append('programId', programId);
  if (conventionStatus) params.append('conventionStatus', conventionStatus); 

  try {
    const response = await axiosInstance.get(
      "/survey-response",
      {
        params,
        headers: {
          Accept: 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses for eligible candidate:', error);
    throw new Error(
      'Failed to fetch survey responses for eligible candidate. Please try again later.'
    );
  }
};



export const sendSurveyResponse = async (token, responseData) => {
  try {
    const response = await axiosInstance.post(
      "/survey-response",
      responseData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending survey response:', error);
    throw new Error('Failed to send the survey response. Please try again later.');
  }
};



export const getSurveyResponsesByUserId = async (userId, queryParams) => {
  if (!userId)return 
  const {
    page = 1,
    limit = 10,
    status,
    searchTerm,
    coachNote,
    startCreatedAt,
    endCreatedAt,
    initialEvaluationNote,
  } = queryParams;

  const params = new URLSearchParams({
    page,
    limit,
  });

  if (status) params.append('status', status);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (coachNote) params.append('coachNote', coachNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (initialEvaluationNote) params.append('initialEvaluationNote', initialEvaluationNote);

  try {
    const response = await axiosInstance.get(
      `/survey-response/by-user/${userId}`,
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses by user ID:', error);
    throw new Error('Failed to fetch survey responses by user ID. Please try again later.');
  }
};


export const getSurveyResponseById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/survey-response/${id}`,
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey response ID:', error);
    throw new Error('Failed to fetch survey response ID. Please try again later.');
  }
};

export const updateSurveyResponseById = async (id, updatedObject) => {
  try {
    const response = await axiosInstance.patch(
      `/survey-response/${id}`,
      updatedObject,
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating the survey response status:', error);
    throw new Error('Failed to update survey response status. Please try again later.');
  }

};

export const getAllSurveyResponsesCoordinateur = async (queryParams = {}) => {
  try {
    // Create URLSearchParams only for valid query parameters
    const params = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        params.append(key, value);
      }
    });

    // Make the API request
    const response = await axiosInstance.get("/coordonator/get-list-condidature", {
      params, // Attach query parameters
      headers: {
        Accept: "application/json",
      },
    });

    // Ensure data exists in the response
    if (!response || !response.data) {
      throw new Error("No data returned from the server.");
    }

    // Return the API response
    return response.data;
  } catch (error) {
    // Enhanced error handling
    console.error("Error fetching survey responses:", error);

    // Customize error messages based on the error type
    const errorMessage =
      error.response?.data?.message ||
      "Failed to fetch survey responses. Please try again later.";

    throw new Error(errorMessage);
  }
};



export const getAllSurveyResponsesExport = async (queryParams) => {
  const params = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value);
    }
  });

  try {
    const response = await axiosInstance.get("/survey-response/", {
      params,
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching survey responses:", error);
    throw new Error("Failed to fetch survey responses. Please try again later.");
  }
};


export const getAllSurveyResponsesEvaluator = async (queryParams) => {
  const params = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value);
    }
  });

  try {
    const response = await axiosInstance.get("/survey-response/", {
      params,
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching survey responses:", error);
    throw new Error("Failed to fetch survey responses. Please try again later.");
  }
};


export const getAllSurveyResponses = async (queryParams) => {
  const params = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value);
    }
  });

  try {
    const response = await axiosInstance.get("/survey-response/", {
      params,
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching survey responses:", error);
    throw new Error("Failed to fetch survey responses. Please try again later.");
  }
};

export const getSurveyResponsesForEvaluator = async (evaluatorKeyCloakId, queryParams = {}) => {
  const {
    page,
    limit,
    status,
    searchTerm,
    evaluatorNote,
    startCreatedAt,
    endCreatedAt,
    programId,
    conventionStatus
  } = queryParams;

  const params = new URLSearchParams();

  if (page !== undefined) params.append('page', page);
  if (limit !== undefined) params.append('limit', limit);
  if (status) params.append('status', status);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (evaluatorNote) params.append('evaluatorNote', evaluatorNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (programId) params.append('programId', programId);
  if (conventionStatus) params.append('conventionStatus', conventionStatus);

  try {
    const response = await axiosInstance.get(
      `/evaluator/${evaluatorKeyCloakId}`,
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses for evaluator:', error);
    throw new Error('Failed to fetch survey responses for evaluator. Please try again later.');
  }
};

export const getAllSurveyResponsesExportPdf = async ({ programId, status }) => {
  if (!programId) {
    throw new Error("Program ID is required for exporting survey responses.");
  }

  try {
    const response = await axiosInstance.get(
      `/survey-response/export/AllCondidature/${programId}`,
      {
        params: status ? { status } : {},
        headers: {
          Accept: "application/json",
        },
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const today = new Date().toISOString().split("T")[0]; 

      const fileName = `Liste_des_Candidatures_${today}.xlsx`;

      const downloadUrl = window.URL.createObjectURL(file);
      const link = document.createElement("a");

      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      throw new Error(`Failed to export. Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error exporting survey responses:", error);
    throw new Error("Failed to export survey responses. Please try again later.");
  }
};


export const assignEvaluationFormToProgram = async (formId, programId) => {
  try {
    const response = await axiosInstance.post(
      `/survey-response/assign-formulaire-evaluation/${formId}/to-program/${programId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error assigning form to program:", error);
    throw new Error("Failed to assign the evaluation form. Please try again.");
  }
};