import React, { useState } from "react";

const Page9 = ({
  fait_at,
  date_signature,
  name,
  signature,
  parapheCanvas,
  signatureUrlCordinateur,
  ParaphCoordinateur,
  luApprove
}) => {
  const isImage = (str) => {
    if (typeof str !== 'string') return false;
  
    const trimmedStr = str.trim();
    const dataUrlPattern = /^data:image\/(png|jpeg|gif|bmp|webp|svg|tiff);base64,/i;

    const urlPattern = /^(https?:\/\/)?[\w.-]+\/.*\.(png|jpeg|jpg|gif|bmp|webp|svg|tiff)$/i;
  
    const isDataUrl = dataUrlPattern.test(trimmedStr);
    const isImageUrl = urlPattern.test(trimmedStr);
    return isDataUrl || isImageUrl;
  };
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm",
        margin: "0 auto",
        padding: "10px",
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <p className="text-justify mb-3">
        préalablement l’objet d’un arrangement à l’amiable. En cas d’échec, les
        parties recourront à une procédure d’arbitrage dont les arbitres seront
        désignés de commun accord.
      </p>

      <p className="text-justify mb-2">
        Si le litige porte uniquement sur le remboursement de la partie du
        financement avec contrepartie (si applicable), pour un montant certain,
        exigible et liquide, les parties conviennent que le recouvrement pourra
        s’opérer suivant la procédure simplifiée de recouvrement de droit OHADA.
      </p>

      <h4 className="font-bold  mb-2">Article 18 : Droit applicable</h4>
      <p className="text-justify mb-2">
        Le droit applicable pour toutes les questions se rapportant à la
        présente convention est le droit en vigueur en République Démocratique
        du Congo.
      </p>

      <h4 className="font-bold  mb-2">
        Article 19 : Adresses et Significations/notifications
      </h4>
      <p className="text-justify mb-2">
        Les parties certifient que les adresses de leurs sièges respectifs
        sus-renseignées sont exactes et véritables, toute
        notification/signification pouvant valablement leur être faite auxdites
        adresses, contre accusé de réception.
      </p>

      <p className="text-justify mb-2">
        Fait à {fait_at}, le {date_signature}.
      </p>

      <div className="flex justify-between mb-1">
        <div>
          <p>Pour le Bénéficiaire</p>
          <p>{name}</p>
          <p> {isImage(luApprove) ? (
        <div
          style={{
            position: 'absolute',

          }}
        >
          <img
            src={luApprove}
            alt="Aperçu de la signature"
            style={{
              width: '30px',
              height: '30px',
              marginLeft: '20px',
              marginTop :'-15px'
            }}
          />
        </div>
      ) : (
        <p style={{
          fontFamily:  "Caveat, cursive",
          fontSize:"25px"
        }}>{luApprove}</p>
      )}</p>
          {signature && (
            <img
              src={signature}
              alt="Aperçu de la signature"
              style={{
                width: "80px",
                height: "75px",
                marginTop: "30px",
              }}
            />
          )}
        </div>

        <div>
          <p className="ms-3 text-[14px]">Pour l’UCP-PADMPME</p>
          <p className="font-semibold mb-1">Alexis MANGALA NGONGO</p>
          <p className="ms-3 text-[14px]">Coordonnateur National</p>
          {signatureUrlCordinateur && (
            <img
              src={signatureUrlCordinateur}
              alt="Aperçu de la signature"
              style={{
                width: "80px",
                height: "75px",
                marginTop: "10px",
                marginLeft: "50px",
              }}
            />
          )}
        </div>
      </div>




      <div className="text-left ">
        <hr className="border-t-2 border-black mb-1"></hr>
 
      </div>


















      <h3 className="font-bold text-lg mt-5">Annexes :</h3>
      <div className="space-y-3">
        <p>1. Plan d’affaires revu après le cadrage</p>
        <p>2. Plan de financement</p>
        <p>3. Plan de travail</p>
        <p>4. Acte constitutif de la contrepartie à la subvention</p>
        <p>
          5. Directives pour la Prévention et la Lutte contre la Fraude et la
          Corruption dans le cadre des Projets financés par la Banque mondiale
        </p>
        <p>6. Coordonnée bancaire</p>
        <p>7. RCCM, N° Impôt, N° Identification Nationale</p>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 9 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page9;
