import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import formation from "../../../assets/ecommerceFormation.png";
import { getFormationsByUserId } from "../../../services/formation.service";
import { AuthContext } from "../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";

const ListeFormationsLaureat = () => {
  const [loading, setLoading] = useState(false);
  const [formations, setFormations] = useState([]);
  const [error, setError] = useState(null);
  const { userId } = useContext(AuthContext);

  const Assignedformation = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getFormationsByUserId(userId);
      setFormations(response || []);
    } catch (err) {
      setError(err.message || "An error occurred while fetching formations.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      Assignedformation(userId);
    }
  }, [Assignedformation, userId]);

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h2 className="text-3xl font-bold mb-2">Liste des formations</h2>
      <p className="text-gray-500 text-sm mb-4">
        Montez en compétences et améliorez votre expertise.
      </p>

      {loading && (
        <div>
          <CircularProgress size={20} />
        </div>
      )}
      {error && (
        <p className="text-red-500">
          Une erreur est survenue lors de la récupération des formations.
        </p>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {formations &&
          formations.map((training, index) => (
            <Link
              to={`/laureat-dashboard/formations/${training.id}`}
              key={index}
              className="no-underline"
            >
              <div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 hover:scale-105">
                <div className="p-3">
                  <img
                    src={training?.bannerImage || formation}
                    alt={training?.title}
                    className="m-auto mt-2 rounded-[10px] h-40 w-[100%] object-cover"
                  />
                  <p className="text-[#3366CC] text-xs whitespace-nowrap px-[10px] py-[4px] mt-4 bg-[#699BF736] w-min rounded-full capitalize">
                    {training?.categories}
                  </p>
                  <h3 className="text-lg text-[black] font-semibold">
                    {training?.title}
                  </h3>
                  <p className="text-gray-600 text-sm mt-1">
                    {training?.trainer}
                  </p>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default ListeFormationsLaureat;
