import React from 'react';
import CheckCircleIcon from '../assets/check_circle.svg';
import TimeProgressIcon from '../assets/lets-icons_time-progress-duotone.svg';

const GetStatusLaureat = ({ status }) => {
  
  if (status !== 'SOUMIS' && status !== 'Brouillon') {
    return <span> --- </span>;
  }

  const color = "text-[#091E42]";
  const StatusIcon = status === 'SOUMIS' ? CheckCircleIcon : TimeProgressIcon;

  return (
    <div className="flex gap-[.5px] items-center">
      <img src={StatusIcon} alt={status} className="w-6 h-6 mr-1" />
      <span className={color}>{status.toLowerCase()}</span>
    </div>
  );
};

export default GetStatusLaureat;
