import React from "react";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

import BannerImage from "../../assets/BannerAppelss.png";

const ContactBanner = () => {
  return (
    <div className="flex justify-center items-center md:w-[80%] mx-auto flex-col h-[50vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative  pt-3">
      <img
        src={BannerImage}
        className="h-[50vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] object-cover absolute md:rounded-2xl "
        alt="Banner"
      />
      <div className="relative flex flex-col text-white md:gap-2   ">
        <h2 className="text-center font-roboto-slab text-2xl sm:text-3xl xl:text-5xl ">
          Contactez-nous
        </h2>
        <div className="flex  flex-col  items-center my-1  ">
          <p className="flex items-center gap-2 md:mb-1">
            <EnvelopeIcon className="w-6 h-6 text-white" />
            <span className="text-lg">support@copa.transforme.cd</span>
          </p>
          <div className="flex items-center gap-2 sm:ml-24 px-1">
            <PhoneIcon className="w-6 h-6 text-white -mt-2" />
            <div className="flex flex-col border-white border-l pl-2 text-sm">
              <span className="">Goma : +243 975 694 577</span>
              <span className="">
                Bukavu : +243 859 789 377
              </span>
              <span className="">
                Bunia : +243 993 672 342
              </span>
              <span className="">
                Mbuji-mayi : +243 856 801 297
              </span>
              <span className="">
                Kinshasa : +243 810 054 009 / +243 840 175 185
              </span>
              <span className="">
                Matadi : +243 833 591 149
              </span>
              <span className="">
                Kasangulu, Kisantu, Mbanza-Ngungu : +243 857 796 560
              </span>
              <span className="">
                Kimpese, Boma, Muanda : +243 857 796 560
              </span>
              <span className="">
                Kananga : +243 837 507 408
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default ContactBanner;
