import axiosInstance from "../helpers/axiosIntercepter";

export const listAllGroups = async (queryParams) => {
  const { page = 1, limit = 20 } = queryParams;

  const params = new URLSearchParams({
    page,
    limit,
  });

  try {
    const response = await axiosInstance.get("/authentication/groups", {
      params,
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching survey responses:", error);
    throw new Error(
      "Failed to fetch survey responses. Please try again later."
    );
  }
};

export const getUserProfile = async (user_id) => {
  try {
    const response = await axiosInstance.get(
      "/authentication/profile/" + user_id,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw new Error(
      "Failed to fetch USER profile. Please try again later."
    );
  }
};

export const getUsersByGroupIdExport = async (
  groupId,
  page = 1,
  perPage = 25,
  filter = ""
) => {
  try {
    const params = {
      group_id: groupId,
      page,
      per_page: perPage,
    };

    if (filter !== "") {
      params.is_email_verified = filter === "true";
    }

    const response = await axiosInstance.get("/users/with-groups", { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching users by group:", error);
    throw new Error("Failed to fetch users. Please try again later.");
  }
};

export const getUsersByGroupId = async (
  groupId,
  page = 1,
  perPage = 10,
  searchTerm = "",
  filter = ""
) => {
  try {
    const params = {
      group_id: groupId,
      page,
      per_page: perPage,
      searchTerm,
    };

    if (filter !== "") {
      params.is_email_verified = filter === "true";
    }

    const response = await axiosInstance.get("/users/with-groups", { params });

    const { docs, per_page, hasNextPage, hasPreviousPage } = response.data;

    return {
      users: docs || [],
      perPage: per_page || perPage,
      hasNextPage: hasNextPage || false,
      hasPreviousPage: hasPreviousPage || false,
      totalDocs: response?.data?.totalDocs || 0,
    };
  } catch (error) {
    console.error("Error fetching users by group:", error);
    throw new Error("Failed to fetch users. Please try again later.");
  }
};

export const sendInvitation = async (invitationData) => {
  try {
    const BASE_URL = "/authentication/invite";
    const response = await axiosInstance.post(BASE_URL, invitationData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      throw new Error("Nom utilisateur existe déjà");
    }
    throw new Error("Something went wrong");
  }
};

export const assignMultipleSurveys = async (coachId, surveyResponseIds) => {
  const payload = {
    coachId,
    surveyResponseIds,
  };

  try {
    const response = await axiosInstance.post(
      "/survey-response/assign-multiple-surveys",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error assigning multiple surveys:", error);
    throw new Error("Failed to assign surveys. Please try again later.");
  }
};

export const toggleUserStatus = async (coachId) => {
  try {
    const response = await axiosInstance.put(
      `/authentication/toggle-status/${coachId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error toggling user status:", error);
    throw new Error("Failed to toggle user status. Please try again later.");
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/authentication/delete-user/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error deleting User with ID: ${id}`, error);
    throw new Error("Failed to delete the User. Please try again later.");
  }
};

export const exportUsersPdf = async (queryParams) => {
  const params = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (value !== undefined && value !== null && value !== "") {
      params.append(key, value);
    }
  });

  try {
    const response = await axiosInstance.get(
      "/user_manager/Users/ExportUsers",
      {
        params,
        headers: {
          Accept: "application/json",
        },
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      const currentDate = new Date().toISOString().split("T")[0];

      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadUrl = window.URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = downloadUrl;

      link.setAttribute(
        "download",
        `Liste_des_utilisateurs_${currentDate}.xlsx`
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      throw new Error(`Failed to export. Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Failed to fetch users. Please try again later.");
  }
};

export const listAllGroupsNumber = async (
  groupId,
  searchTerm = "",
  filter = ""
) => {
  try {
    const params = {
      group_id: groupId,
      searchTerm,
    };

    if (filter !== "") {
      params.is_email_verified = filter === "true";
    }

    const response = await axiosInstance.get("/users/get-users-stats", {
      params,
      headers: {
        Accept: "application/json",
      },
    });
    const { total_count, users } = response.data;

    const verifiedUsers = users.filter((user) => user.emailVerified).length;
    const nonVerifiedUsers = users.length - verifiedUsers;

    return {
      total: total_count,
      verified: verifiedUsers,
      non_verified: nonVerifiedUsers,
      users,
    };
  } catch (error) {
    console.error("Error fetching user statistics:", error);
    throw new Error("Failed to fetch user statistics. Please try again later.");
  }
};

export const listAllEvaluator = async (
  page = 1,
  perPage = 10,
  searchTerm = "",
) => {
  try {
    const params = {
      page,
      per_page: perPage,
      searchTerm,
    };
    const response = await axiosInstance.get("/evaluator/list/all", { params });

    const { per_page, hasNextPage, hasPreviousPage, page: currentPage, totalDocs, totalPages, docs } = response.data;

    return {
      users: docs || [], 
      perPage: per_page || perPage,
      hasNextPage: hasNextPage || false,
      hasPreviousPage: hasPreviousPage || false,
      totalDocs: totalDocs || 0,
      totalPages: totalPages || 0,
      currentPage: currentPage || page,
    };
  } catch (error) {
    console.error("Error fetching evaluators:", error);
    throw new Error("Failed to fetch evaluators. Please try again later.");
  }
};
