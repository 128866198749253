import { toast } from "react-toastify";
import axiosInstance from "../helpers/axiosIntercepter";

export const evaluateCandidate = async (
  surveyResponseId,
  evaluatorKeyCloakId,
  formData
) => {
  try {
    const response = await axiosInstance.post(
      `/evaluator/evaluate/${surveyResponseId}/${evaluatorKeyCloakId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating evaluation status:", error);
    throw new Error(
      "Failed to update candidate status. Please try again later."
    );
  }
};

export const assignEvaluator = async (
  surveyResponseId,
  evaluatorKeyCloakId
) => {
  try {
    const response = await axiosInstance.post(
      `/evaluator/assign/${surveyResponseId}/${evaluatorKeyCloakId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error assigning evaluator:",
      error.response ? error.response.data : error.message
    );
    throw new Error(
      error.response?.data?.message ||
        "Failed to assign evaluator. Please try again later."
    );
  }
};

export const unassignEvaluator = async (surveyResponseId) => {
  try {
    const response = await axiosInstance.post(
      `/evaluator/unassign/${surveyResponseId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error unassigning evaluator:",
      error.response ? error.response.data : error.message
    );
    throw new Error(
      error.response?.data?.message ||
        "Failed to unassign evaluator. Please try again later."
    );
  }
};


export const assignEvaluatorAuto = async (programId) => {
  try {
    const response = await axiosInstance.post(`/evaluator/assign-auto?programId=${programId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error assigning auto evaluator:",
      error.response ? error.response.data : error.message
    );
    throw new Error(
      error.response?.data?.message ||
        "Échec de l'assignation automatique. Veuillez réessayer plus tard."
    );
  }
};


export const evaluationApprofondie = async (
  surveyResponseId,
  formData
) => {
  try {
    const response = await axiosInstance.patch(
      `/survey-response/${surveyResponseId}/evaluation-approfondie`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating evaluation status:", error);
    throw new Error(
      "Failed to update evaluation status. Please try again later."
    );
  }
};

