import React, { useEffect, useRef, useState } from 'react';
import Convention from '../../../components/convention/Convention';

const VisulaizePdf = ({ survey_response_id, formData, signature, praphe ,luApprove }) => {

  const conventionRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);


  const enterFullScreen = () => {
    const elem = conventionRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { 
      elem.msRequestFullscreen();
    }
  };


  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { 
      document.msExitFullscreen();
    }
  };


  const toggleFullScreen = () => {
    if (!isFullscreen) {
      enterFullScreen();
    } else {
      exitFullScreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      const fsElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;

      setIsFullscreen(!!fsElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return (
    <div className="relative w-full h-[calc(100vh-400px)] overflow-y-auto bg-gray-100">
      <div
        ref={conventionRef}
        className={`${
          isFullscreen
            ? 'w-full h-full overflow-y-auto bg-gray-100'
            : 'relative w-full h-[calc(100vh-400px)] overflow-y-auto bg-gray-100'
        }`}
      >
        <Convention
          survey_response_id={survey_response_id}
          formData={formData}
          signature={signature}
          parapheCanvas={praphe}
          luApprove={luApprove}
        />
      </div>
      <button
        type="button"
        onClick={toggleFullScreen}
        className="absolute top-2 right-2 bg-sky-500 text-white py-2 px-4 rounded shadow-md hover:bg-sky-600 transition-all text-sm"
      >
        {isFullscreen ? 'Exit Fullscreen' : 'Plein écran'}
      </button>
    </div>
  );
};

export default VisulaizePdf;
