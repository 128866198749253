import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../../pages/Auth/Login/Login";
import Register from "../../pages/Auth/Sign-Up/register";
import ForgetPassword from "../../pages/Auth/Forget-Password/ForgetPassword";
import AppelsCandidature from "../../pages/AppelsCandidature";
import DetailsAppelCandudature from "../../pages/DetailsAppelCandidature";
import CandidateList from "../../pages/coach/ListeCandidatures/liste-condidature";
import LaureatGetProgramSurvey from "../../features/LaureatGetProgramSurvey";
import DashboardStatistics from "../../pages/Unite-De-Gestion-Dashboard/DashboardStatistics";
import SurveyCreatorWidget from "../../features/AdminSurveyCreator";
import LayoutLandingPage from "../../components/common/LayoutLandingPage";
import About from "../../pages/About";
import Contact from "../../pages/Contact";
import Layout from "../../components/common/Layout";
import ListeDesAppelsACandidatures from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures";
import PrivateRoute from "../../helpers/PrivateRoute";
import ListeDesFormulaires from "../../pages/Unite-De-Gestion-Dashboard/ListeDesFormulaires";
import ListeDesCoachs from "../../pages/Unite-De-Gestion-Dashboard/ListesDesUtilisteurs";
// import Reglages from "../../pages/Unite-De-Gestion-Dashboard/Reglages";
import CreateAppelACandidature from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures/CreateAppelACandidature";
import UDGSurveyCreator from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures/CreateAppelACandidature/Survey/UDGSurveyCreator";
import { StepProvider } from "../../contexts/StepContext";
import Formulaires from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures/CreateAppelACandidature/Formulaires";
import LaureatReglages from "../../pages/Laureat-Dashboard/Reglages";
import LaureatStatistics from "../../pages/Laureat-Dashboard/LaureatStatistics";
// import EvaluationDetails from "../../pages/coach/ListeCandidatures/EvaluationDetails";
// import ConsortiumDashboard from "../../pages/Consortium-Dashboard";
// import ListeDesCandidatures from "../../pages/Consortium-Dashboard/ListeDesCandidatures/ListeDesCandidatures";
// import FormulaireDeCadrage from "../../pages/coach/ListeCandidatures/listesFormulairesCadrage/ListeDesFormulairesCadrage";
// import ReglageConsortium from "../../pages/Consortium-Dashboard/reglage/ReglageConsortium";
import DetailsFormulaireJuridique from "../../pages/coach/ListeCandidatures/FormulaireJuridique/DetailsFormulaireJuridique";
// import ListeFormJuridique from "../../pages/coach/ListeCandidatures/FormulaireJuridique/ListeFormJuridique";
import CreateFormJur from "../../pages/coach/ListeCandidatures/FormulaireJuridique/CreateFormJur";
import DetailsAppelACandidatureSoumis from "../../pages/Laureat-Dashboard/DetailsAppelACandidatureSoumis";
import ListeCandidatures from "../../pages/Unite-De-Gestion-Dashboard/ListeCandidatures";
import AssignCoach from "../../pages/Unite-De-Gestion-Dashboard/ListeCandidatures/AssignCoach";
import UCPDashboard from "../../pages/UCPDashboard";
import UCPReglages from "../../pages/UCPDashboard/reglages";
import ListeDesCandidaturesEligible from "../../pages/UCPDashboard/listeDesCandidaturesEligible";
import GetFormJuridique from "../../pages/Laureat-Dashboard/LaureatStatistics/GetFormJuridique";
import UCPDetails from "../../pages/UCPDashboard/details";
import DetailsFormulaireUnite from "../../pages/Unite-De-Gestion-Dashboard/ListeDesFormulaires/DetailsForm/DetailsFormulaireUnite";
import CoachStatistics from "../../pages/coach/CoachStatistics";
import CoachReglages from "../../pages/coach/CoachReglages";
import UDGReglages from "../../pages/Unite-De-Gestion-Dashboard/Reglages";
import UpdateSurveyForm from "../../pages/Unite-De-Gestion-Dashboard/ListeDesFormulaires/UpdateFormulaire/UpdateSurveyForm";
// import DetailsAppelCandidature from "../../pages/DetailsAppelCandidature";
import DetailsAppelACandidature from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures/DetailsAppelACandidature";
import { CircularProgress } from "@mui/material";
import ReglageCoordinateur from "../../pages/Coordinateur-Dashboard/Reglage/ReglageCoordinateur";
import EvaluateurDashboard from "../../pages/Evaluateur-Dashboard";
import ListeDesCandidaturesEvaluateur from "../../pages/Evaluateur-Dashboard/ListeDesCandidaturesEvaluateur/ListeDesCandidaturesEvaluateur";
import ReglageEvaluateur from "../../pages/Evaluateur-Dashboard/Reglages/ReglageEvaluateur";
import CoordinateurDashboard from "../../pages/Coordinateur-Dashboard";
import ListeDesCandidaturesCoordinateur from "../../pages/Coordinateur-Dashboard/ListeDesCandidatures/ListeDesCandidaturesCoordinateur";
import DetailsCandidatures from "../../pages/Unite-De-Gestion-Dashboard/ListeCandidatures/DetailsCandidat/DetailsCandidatures";
// import Actualites from "../../pages/LandingPage/Actualites";
import ActualitesPage from "../../pages/Actualites";
import ListeActualites from "../../pages/Unite-De-Gestion-Dashboard/ListeDesActualites";
import CreateActualite from "../../pages/Unite-De-Gestion-Dashboard/ListeDesActualites/CreateActualite";
import DetailsActualite from "../../pages/Actualites/DetailsActualite";
import UpdateActualite from "../../pages/Unite-De-Gestion-Dashboard/ListeDesActualites/UpdateActualite";
import DetailsCandidaturesEvaluateur from "../../pages/Evaluateur-Dashboard/ListeDesCandidaturesEvaluateur/DetailsCandidatures/DetailsCandidaturesEvaluateur";
import UpdateUtilisateur from "../../pages/Unite-De-Gestion-Dashboard/ListesDesUtilisteurs/UpdateUtilisateur";
import GetFormulaire from "../../pages/Laureat-Dashboard/LaureatStatistics/GetAnnexeCadrage";
import ListeDesFormulairesAnnexe from "../../pages/coach/ListeCandidatures/listesFormulairesAnnexe/ListeDesFormulairesAnnexe";
import DetailsDocumentCadrage from "../../pages/Laureat-Dashboard/LaureatStatistics/DetailsDocumentAnnexe";
import ListeDesFormulairesCadrage from "../../pages/coach/ListeCandidatures/listesFormulairesCadrage/ListeDesFormulairesCadrage";
import CadrageDetails from "../../pages/coach/ListeCandidatures/CadrageDetails";
import UploadDocumentCadrage from "../../pages/coach/ListeCandidatures/listesFormulairesCadrage/UploadDocumentCadrage/UploadDocumentCadrage";
import Convention from "../../components/convention/Convention";
import DetailsCandidature from "../../pages/Coordinateur-Dashboard/ListeDesCandidatures/DetailsCandidature/DetailsCandidature";
import ListeFormations from "../../pages/coach/ListeFormations/ListeFormations";
import CreateFormation from "../../pages/coach/ListeFormations/CreateFormation/CreateFormation";
import ListeFormationsLaureat from "../../pages/Laureat-Dashboard/ListeFormations/ListeFormationsLaureat";
import DetailsFormation from "../../pages/Laureat-Dashboard/ListeFormations/DetailsFormation";
import AssignFormation from "../../pages/coach/ListeFormations/AssignFormation/AssignFormation";
import UpdateFormation from "../../pages/coach/ListeFormations/UpdateFormation/UpdateFormation";
import DetailsFormationCoach from "../../pages/coach/ListeFormations/DetailsFormation/DetailsFormationCoach";
import AssignEvaluator from "../../pages/Unite-De-Gestion-Dashboard/ListeCandidatures/AssignEvaluator";
import FournisseurDashboard from "../../pages/Fournisseur-Dashboard";
import ReglageFournisseur from "../../pages/Fournisseur-Dashboard/Reglages/ReglageFournisseur";
import ListeAchats from "../../pages/Fournisseur-Dashboard/ListeAchats/ListeAchats";
import ListeAchatsLaureat from "../../pages/Laureat-Dashboard/ListeAchatLaureat/ListeAchatsLaureat";
import CreateDevis from "../../pages/Laureat-Dashboard/ListeAchatLaureat/CreateDevis";
import { StepDevisProvider } from "../../contexts/StepperDevisContext";
import ListeAchatsCoach from "../../pages/coach/ListeAchats/ListeAchatsCoach";
import DetailsAchat from "../../pages/coach/ListeAchats/DetailsAchat/DetailsAchat";

const LandingPage = React.lazy(() => import("../../pages/LandingPage"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutLandingPage />,
    children: [
      {
        index: true,
        element: (
          <Suspense
            fallback={
              <div className="flex justify-center items-center p-[20%]">
                <CircularProgress />
              </div>
            }
          >
            <LandingPage />
          </Suspense>
        ),
      },
      { path: "a-propos", element: <About /> },

      { path: "appels-a-candidature", element: <AppelsCandidature /> },
      { path: "convention", element: <Convention /> },
      {
        path: "appels-a-candidature/details/:id",
        element: <DetailsAppelCandudature />,
      },
      { path: "actualites", element: <ActualitesPage /> },
      {
        path: "actualite/details/:id",
        element: <DetailsActualite />,
      },
      { path: "contact", element: <Contact /> },
    ],
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/register",
    element: <Register />,
  },

  {
    path: "/auth/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "laureat-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <LaureatStatistics /> },
          { path: "details/:id", element: <DetailsAppelACandidatureSoumis /> },
          { path: "fill-form", element: <LaureatGetProgramSurvey /> },
          {
            path: "formations",
            element: <ListeFormationsLaureat />,
          },
          {
            path: "formations/:id",
            element: <DetailsFormation />,
          },
          { path: "reglages", element: <LaureatReglages /> },
          { path: "achat", element: <ListeAchatsLaureat /> },
          {
            path: "achat/create",
            element: (
              <StepDevisProvider>
                <CreateDevis />
              </StepDevisProvider>
            ),
          },
          {
            path: "formulaireCadrage/:id",
            element: <GetFormulaire />,
          },
          {
            path: "formulaireCadrage/details/:id",
            element: <DetailsDocumentCadrage />,
          },
          {
            path: "formJuridique/:surveyResponseId",
            element: <GetFormJuridique />,
          },
        ],
      },
    ],
  },
  {
    path: "coach-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <CoachStatistics /> },
          {
            path: "candidatures",
            element: <CandidateList />,
          },
          {
            path: "formations",
            element: <ListeFormations />,
          },
          {
            path: "formation/details/:id",
            element: <DetailsFormationCoach />,
          },
          {
            path: "formations/:id",
            element: <AssignFormation />,
          },
          {
            path: "formations/update/:id",
            element: <UpdateFormation />,
          },
          {
            path: "formations/create-formation",
            element: <CreateFormation />,
          },
          {
            path: "candidatures/:id/formulaires",
            element: <ListeDesFormulairesAnnexe />,
          },
          {
            path: "candidatures/formulaires-cadrage/:id",
            element: <ListeDesFormulairesCadrage />,
          },
          {
            path: "/coach-dashboard/candidatures/formulaires-cadrage/:id/upload/:formId",
            element: <UploadDocumentCadrage />,
          },
          {
            path: "candidatures/:id",
            element: <CadrageDetails />,
          },

          // {
          //   path: "candidatures/:id/formulaires-juridique",
          //   element: <ListeFormJuridique />,
          // },
          {
            path: "candidatures/:id/details-juridique",
            element: <DetailsFormulaireJuridique />,
          },
          {
            path: "candidatures/:id/formulaires-juridique/creer-form-juridique",
            element: <CreateFormJur />,
          },

          { path: "fournisseurs", element: <SurveyCreatorWidget /> },
          { path: "achat", element: <ListeAchatsCoach /> },
          { path: "achat/:id", element: <DetailsAchat /> },
          { path: "reglages", element: <CoachReglages /> },
        ],
      },
    ],
  },
  {
    path: "unite_de_gestion-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <DashboardStatistics /> },
          {
            path: "Appels-à-candidatures",
            element: <ListeDesAppelsACandidatures />,
            children: [
              {
                path: "create-programme",
                element: (
                  <StepProvider>
                    <CreateAppelACandidature />
                  </StepProvider>
                ),
                children: [
                  { path: "create-form", element: <UDGSurveyCreator /> },
                  { path: "assign-form", element: <Formulaires /> },
                ],
              },
            ],
          },
          {
            path: "Appels-à-candidatures/:id",
            element: <DetailsAppelACandidature />,
          },
          {
            path: "formulaires",
            element: <ListeDesFormulaires />,
            children: [
              { path: "create-form", element: <SurveyCreatorWidget /> },
            ],
          },
          { path: "formulaires/:id", element: <DetailsFormulaireUnite /> },
          { path: "formulaires/:id/update", element: <UpdateSurveyForm /> },
          { path: "liste-utilisateurs", element: <ListeDesCoachs /> },
          {
            path: "liste-utilisateurs/update/:user_id",
            element: <UpdateUtilisateur />,
          },
          { path: "liste-candidatures", element: <ListeCandidatures /> },
          {
            path: "liste-candidatures/details/:id",
            element: <DetailsCandidatures />,
          },
          {
            path: "liste-actualites",
            element: <ListeActualites />,
          },
          {
            path: "liste-actualites/create-actualite",
            element: <CreateActualite />,
          },
          {
            path: "actualite/update/:id",
            element: <UpdateActualite />,
          },

          { path: "liste-candidatures/assign-coach", element: <AssignCoach /> },
          {
            path: "liste-candidatures/assign-evaluator",
            element: <AssignEvaluator />,
          },
          { path: "reglage", element: <UDGReglages /> },
        ],
      },
    ],
  },
  {
    path: "unite_de_coordination_de_projet-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <UCPDashboard /> },
          { path: "candidatures", element: <ListeDesCandidaturesEligible /> },
          { path: "candidatures/details/:id", element: <UCPDetails /> },
          { path: "reglage", element: <UCPReglages /> },
        ],
      },
    ],
  },
  // {
  //   path: "consortium-dashboard",
  //   element: <PrivateRoute />,
  //   children: [
  //     {
  //       element: <Layout />,
  //       children: [
  //         { index: true, element: <ConsortiumDashboard /> },
  //         { path: "candidatures", element: <ListeDesCandidatures /> },
  //         { path: "reglage", element: <ReglageConsortium /> },
  //       ],
  //     },
  //   ],
  // },
  {
    path: "coordinateur-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <CoordinateurDashboard /> },
          {
            path: "candidatures",
            element: <ListeDesCandidaturesCoordinateur />,
          },
          { path: "candidatures/:id", element: <DetailsCandidature /> },
          { path: "reglages", element: <ReglageCoordinateur /> },
        ],
      },
    ],
  },
  {
    path: "evaluateur-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <EvaluateurDashboard /> },
          { path: "candidatures", element: <ListeDesCandidaturesEvaluateur /> },
          {
            path: "candidatures/:id",
            element: <DetailsCandidaturesEvaluateur />,
          },
          { path: "reglages", element: <ReglageEvaluateur /> },
        ],
      },
    ],
  },
  {
    path: "fournisseur-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <FournisseurDashboard /> },
          { path: "formations", element: <ListeDesCandidaturesCoordinateur /> },
          { path: "achats", element: <ListeAchats /> },
          { path: "reglages", element: <ReglageFournisseur /> },
        ],
      },
    ],
  },
]);

export default router;
