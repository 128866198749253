import React, { useContext, useState } from "react";
import { StepDevisContext } from "../../../../contexts/StepperDevisContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Button } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CreatableSelect from "react-select/creatable";

const StepOneAddDevis = ({ handleNext }) => {
  const { formData, setFormData, setImages } = useContext(StepDevisContext);

  const initialValues = formData;

  const [categorySuggestions, setCategorySuggestions] = useState([
    { value: "technology", label: "Technologie" },
    { value: "business", label: "Business" },
    { value: "education", label: "Éducation" },
    { value: "health", label: "Santé" },
  ]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Le titre est requis")
      .min(3, "Le titre doit contenir au moins 3 caractères"),
    submissionDate: Yup.date()
      .required("La date limite de soumission est requise")
      .nullable()
      .min(
        new Date(),
        "La date limite de soumission doit être ultérieure à aujourd'hui"
      ),
    description: Yup.string().required("La description est requise"),
    exigence: Yup.string()
      .required("L'exigence est requise")
      .min(10, "L'exigence doit contenir au moins 10 caractères"),
    status: Yup.string().required("Le statut est requis"),
    images: Yup.mixed().required("Veuillez sélectionner une image"),
  });

  const onSubmit = (values) => {
    setFormData(values);
    handleNext();
  };

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    setImages([file]);
    setFieldValue("images", file);
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, isValid, values }) => (
        <Form className="p-8 bg-white shadow-md rounded-lg mt-2">
          <div className="flex flex-col">
            {/* Title Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Nom de la demande
              </label>
              <Field
                type="text"
                name="title"
                className="w-full p-3 border border-gray-400 rounded-md"
              />
              <ErrorMessage
                name="title"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Submission Date Input */}
            {/* <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Date limite de soumission
              </label>
              <Field
                type="date"
                name="submissionDate"
                className="w-full p-3 border border-gray-400 rounded-md"
              />
              <ErrorMessage
                name="submissionDate"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div> */}

            {/* Status Input */}
            {/* <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Statut
              </label>
              <Field
                as="select"
                name="status"
                className="w-full p-3 border border-gray-400 rounded-md"
              >
                <option value="Publié">publié</option>
                <option value="Brouillon">brouillon</option>
                <option value="Fermé">fermé</option>
              </Field>
              <ErrorMessage
                name="status"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div> */}

            {/* Image Upload Input */}
            {/* <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Image
              </label>
              <input
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                onChange={(e) => handleFileChange(e, setFieldValue)}
                className="w-full p-3 border border-gray-400 rounded-md"
              />
              <ErrorMessage
                name="images"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div> */}

            {/* Description Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Description des besoins
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={values.description}
                onChange={(event, editor) =>
                  setFieldValue("description", editor.getData())
                }
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
          </div>
          <div className="flex flex-col">
            {/* Exigence Input */}
            {/* <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Exigences
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={values.exigence}
                onChange={(event, editor) =>
                  setFieldValue("exigence", editor.getData())
                }
              />
              <ErrorMessage
                name="exigence"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div> */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Budget estimé
              </label>
              <CreatableSelect
                isMulti
                options={categorySuggestions}
                value={values.category}
                onChange={(newValue) => {
                  setFieldValue("category", newValue);
                  setCategorySuggestions((prev) => [
                    ...new Set([
                      ...prev,
                      ...newValue.map((opt) => ({
                        value: opt.value,
                        label: opt.label,
                      })),
                    ]),
                  ]);
                }}
                placeholder="Choisissez ou ajoutez des catégories"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            {/* Category Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Catégorie
              </label>
              <CreatableSelect
                isMulti
                options={categorySuggestions}
                value={values.category}
                onChange={(newValue) => {
                  setFieldValue("category", newValue);
                  setCategorySuggestions((prev) => [
                    ...new Set([
                      ...prev,
                      ...newValue.map((opt) => ({
                        value: opt.value,
                        label: opt.label,
                      })),
                    ]),
                  ]);
                }}
                placeholder="Choisissez ou ajoutez des catégories"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Sous catégories
              </label>
              <CreatableSelect
                isMulti
                options={categorySuggestions}
                value={values.category}
                onChange={(newValue) => {
                  setFieldValue("category", newValue);
                  setCategorySuggestions((prev) => [
                    ...new Set([
                      ...prev,
                      ...newValue.map((opt) => ({
                        value: opt.value,
                        label: opt.label,
                      })),
                    ]),
                  ]);
                }}
                placeholder="Choisissez ou ajoutez des catégories"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>

          {/* Next Button */}
          <div className="d-flex justify-content-end mt-4">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ArrowRightIcon className="w-5 h-5" />}
              // disabled={!isValid}
              className=" bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              style={{ textTransform: "none" }}
            >
              Suivant
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepOneAddDevis;
