import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import Avatar from "react-avatar";
import { updateProfileService } from "../../services/auth.service";
import { getVilles } from "../../services/ville.service";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

const UserSettings = ({ userData }) => {
  const { fetchUserProfile } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    nom: "",
    prenom: "",
    ville: "",
    postnom: "",
    selectedVilleId: "",
  });
  const [isEditable, setIsEditable] = useState(false);
  const [villesOptions, setVillesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [prenomError, setPrenomError] = useState(false);
  const [nomError, setNomError] = useState(false);
  const [villeError, setVilleError] = useState(false);

  const fetchVilles = async (searchTerm = "") => {
    setIsLoading(true);
    try {
      const { data } = await getVilles(searchTerm);
      const options = data.map((ville) => ({
        value: ville.id,
        label: ville.name,
      }));
      setVillesOptions(options);
    } catch (error) {
      console.error("Error fetching villes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVilles();
  }, []);

  useEffect(() => {
    if (userData) {
      setFormData({
        email: userData?.email || "",
        nom: userData?.lastName || "",
        prenom: userData?.firstName || "",
        ville: userData?.attributes?.ville?.[0] || "",
        postnom: userData?.attributes?.postnom?.[0] || "",
        selectedVilleId: userData?.attributes?.ville_id || "",
      });
    }
  }, [userData, isEditable]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleModifierClick = () => {
    setIsEditable(true);
  };

  const handleEnregistrerClick = async () => {
    // Reset errors before validation
    setPrenomError(false);
    setNomError(false);
    setVilleError(false);

    // Validation logic
    if (!formData.prenom) {
      setPrenomError(true);
      toast.error("Le champ Prénom est obligatoire", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }

    if (!formData.nom) {
      setNomError(true);
      toast.error("Le champ Nom est obligatoire", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }

    if (!formData.ville) {
      setVilleError(true);
      toast.error("Le champ Ville est obligatoire", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }

    const updatedData = {
      first_name: formData.prenom,
      last_name: formData.nom,
      post_name: formData.postnom || "",
      ville_id: formData.selectedVilleId,
    };

    try {
      await updateProfileService(userData?.id, updatedData);
      setIsEditable(false);
      toast.success("Profil mis à jour avec succès", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      await fetchUserProfile(userData?.id);
    } catch (error) {
      console.error("Error updating profile", error);
      toast.error("Erreur lors de la mise à jour du profil", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  const capitalize = (str) =>
    str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();

  return (
    <Box className="mx-auto p-6 text-foreground mb-5 bg-slate-50 h-[40%]">
      <Paper
        elevation={3}
        className="border rounded-lg bg-white overflow-hidden shadow-none"
      >
        <Box className="bg-gradient-to-r from-[#c1e0f1] to-[#f9d9db] w-full h-10 flex items-center pl-4 text-md font-semibold">Informations Personnelles</Box>
        <Box>
          <div className="flex items-center justify-between px-6 pt-4 gap-2">
            <div className="flex items-center gap-2">
              <Avatar
                name={`${capitalize(formData?.prenom)} ${capitalize(
                  formData?.nom
                )}`}
                round={true}
                size="34"
              />
              <div className="leading-5">
                <p className="mb-0 font-bold ">{` ${formData.prenom} ${formData.nom} `}</p>
                <span className="text-xs">{formData.email}</span>
              </div>
            </div>
            {!isEditable && (
              <Button
                variant="contained"
                color="primary"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[20px] py-[8px] rounded-lg shadow-none text-sm font-normal"
                onClick={handleModifierClick}
                style={{ textTransform: "none" }}
              >
                Modifier
              </Button>
            )}
          </div>
        </Box>

        <Box className="p-6">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Nom</label>
                <TextField
                  fullWidth
                  placeholder="Nom"
                  variant="outlined"
                  name="nom"
                  value={formData.nom}
                  onChange={handleChange}
                  error={nomError}
                  helperText={nomError ? "Le nom est requis" : ""}
                  disabled={!isEditable}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Prénom</label>
                <TextField
                  fullWidth
                  placeholder="Prénom"
                  variant="outlined"
                  name="prenom"
                  value={formData.prenom}
                  onChange={handleChange}
                  error={prenomError}
                  helperText={prenomError ? "Le prénom est requis" : ""}
                  disabled={!isEditable}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">
                  Post nom
                </label>
                <TextField
                  fullWidth
                  placeholder="Post nom"
                  variant="outlined"
                  name="postnom"
                  value={formData.postnom}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Ville</label>
                <FormControl fullWidth error={villeError}>
                  <Select
                    className="h-[56px] "
                    name="ville"
                    value={formData.ville}
                    onChange={(e) => {
                      handleChange(e);
                      const selected = villesOptions.find(
                        (option) => option.label === e.target.value
                      );
                      setFormData((prev) => ({
                        ...prev,
                        selectedVilleId: selected ? selected.value : "",
                      }));
                    }}
                    disabled={!isEditable}
                    renderValue={(selected) => selected || "Choisir une ville"}
                  >
                    {isLoading ? (
                      <MenuItem disabled>
                        <CircularProgress size={20} />
                        Chargement...
                      </MenuItem>
                    ) : (
                      villesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {villeError && (
                    <FormHelperText>Ville est requis</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {isEditable && (
            <Box className="mt-4 justify-end flex gap-2">
              <Button
                variant="outlined"
                className="text-[#0976BC] border-[#0976BC] hover:border-[#065A94] hover:bg-[#065A94] hover:text-white transition-colors duration-300 px-[20px] py-[8px] rounded-lg shadow-none text-sm font-normal"
                style={{ textTransform: "none" }}
                onClick={() => {
                  setIsEditable(false);
                  setVilleError(false);
                  setNomError(false);
                  setPrenomError(false);
                }}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[20px] py-[8px] rounded-lg shadow-none text-sm font-normal"
                onClick={handleEnregistrerClick}
                style={{ textTransform: "none" }}
              >
                Enregistrer
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
      <ToastContainer />
    </Box>
  );
};

export default UserSettings;
