import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import {
  getAllSurveyResponses,
  getAllSurveyResponsesEvaluator,
} from "../../../services/surveyResponse.service";
import GetStatus from "../../../helpers/getStatus";
import assignCoachIcon from "../../../assets/assignCoachIcon.png";
import { toast, ToastContainer } from "react-toastify";
import { assignEvaluatorAuto } from "../../../services/evaluation.service";
import { getAllProgramsFilters } from "../../../services/program.service";
import ExportButtonDropdown from "./ExportButtonDropdown";

const ListeCandidatures = () => {
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [candidatures, setCandidatures] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingAssignAuto, setLoadingAssignAuto] = useState(false);
  const [totalCountEvaluator, setTotalCountEvaluator] = useState(0);
  const [loadingEvaluator, setLoadingEvaluator] = useState(false);
  const [pageEvaluator, setPageEvaluator] = useState(0);
  const [searchTermEvaluator, setSearchTermEvaluator] = useState("");
  const [selectedEvaluator, setSelectedEvaluator] = useState([]);
  const [rowsPerPageEvaluator, setRowsPerPageEvaluator] = useState(10);
  const [candidaturesEvaluator, setCandidaturesEvaluator] = useState([]);
  const [statusEvaluator, setStatusEvaluator] = useState("");
  const [evalActionstatus, setEvalActionstatus] = useState("");
  const [selectedProgramTableOne, setSelectedProgramTableOne] = useState({});
  const [selectedProgramTableTwo, setSelectedProgramTableTwo] = useState({});
  const [programsTableOne, setProgramsTableOne] = useState([]);
  const [programsTableTwo, setProgramsTableTwo] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const statusOptions = [
    { value: "", label: "Tous les statuts" },
    { value: "Retenue", label: "Retenue" },
    { value: "Sélectionné", label: "Sélectionné" },
    { value: "SOUMIS", label: "Soumis" },
    { value: "Brouillon", label: "Brouillon" },
    { value: "Rejeté", label: "Rejeté" },
    { value: "À Évaluer", label: "À Évaluer" },
  ];

  const evalActionStatus = [
    { value: "", label: "Évaluation statut" },
    { value: "Éligible", label: "Éligible" },
    { value: "Rejeté", label: "Non Éligible" },
    { value: "En attente", label: "En attente" },
  ];

  // evaluator
  const fetchCandidaturesEvaluateur = useCallback(async () => {
    setLoadingEvaluator(true);
    try {
      const response = await getAllSurveyResponsesEvaluator({
        page: pageEvaluator + 1,
        limit: rowsPerPageEvaluator,
        searchTerm: searchTermEvaluator,
        status: statusEvaluator,
        evaluationActionStatus: evalActionstatus,
        programId: selectedProgramTableOne?.id,
      });
      setCandidaturesEvaluator(response.data);
      setTotalCountEvaluator(response.totalDocs);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoadingEvaluator(false);
    }
  }, [
    pageEvaluator,
    rowsPerPageEvaluator,
    searchTermEvaluator,
    statusEvaluator,
    evalActionstatus,
    selectedProgramTableOne,
  ]);

  useEffect(() => {
    if (selectedProgramTableOne?.id) {
      fetchCandidaturesEvaluateur();
    }
  }, [selectedProgramTableOne, fetchCandidaturesEvaluateur]);

  const handleStatusChangeEvaluator = (event) => {
    setStatusEvaluator(event.target.value);
    setPageEvaluator(0);
  };

  const handleEvaluationActionStatus = (event) => {
    setEvalActionstatus(event.target.value);
    setPageEvaluator(0);
  };

  const handleChangePageEvaluator = (event, newPage) => {
    setPageEvaluator(newPage);
  };

  const handleChangeRowsPerPageEvaluator = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPageEvaluator(newRowsPerPage);
    setPageEvaluator(0);
  };

  const debouncedSearchEvaluator = useCallback(
    debounce((value) => {
      setSearchTermEvaluator(value);
      setPageEvaluator(0);
    }, 300),
    []
  );

  const handleSearchChangeEvaluator = (event) =>
    debouncedSearchEvaluator(event.target.value);

  const handleClickEvaluator = (event, id, candidature) => {
    const evaluatorIds = candidature?.evaluationActions?.map(
      (elem) => elem?.evaluatorKeyCloakId
    );

    if (evaluatorIds && evaluatorIds.some((id) => id)) {
      console.log(`Candidature ${id} is already assigned to an evaluator.`);
      return;
    }

    const selectedIndex = selectedEvaluator.indexOf(id);
    const newSelected = [...selectedEvaluator];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedEvaluator(newSelected);
  };

  const isSelectedEvaluator = (id) => selectedEvaluator.indexOf(id) !== -1;

  //coach
  const fetchCandidatures = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllSurveyResponses({
        page: page + 1,
        limit: rowsPerPage,
        searchTerm,
        status,
        programId: selectedProgramTableTwo?.id,
      });
      setCandidatures(response.data);
      setTotalCount(response.totalDocs);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, searchTerm, status, selectedProgramTableTwo]);

  useEffect(() => {
    if (selectedProgramTableTwo?.id) {
      fetchCandidatures();
    }
  }, [selectedProgramTableTwo, fetchCandidatures]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleSelectAllClick = (event) => {
  //   setSelected(
  //     event.target.checked
  //       ? candidatures.map((candidature) => candidature.id)
  //       : []
  //   );
  // };

  const handleClick = (event, id, candidature) => {
    if (candidature?.keycloakCoachId !== null) return;

    const selectedIndex = selected.indexOf(id);
    const newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(0);
    }, 300),
    []
  );

  const handleSearchChange = (event) => debouncedSearch(event.target.value);

  const handleAssignCoachClick = () => {
    if (selected.length > 0) {
      const queryParams = selected.map((id) => `ids=${id}`).join("&");
      navigate(`assign-coach?${queryParams}`);
    }
  };

  const handleAssignEvaluatorClick = () => {
    if (selectedEvaluator.length > 0) {
      const queryParams = selectedEvaluator.map((id) => `ids=${id}`).join("&");
      navigate(`assign-evaluator?${queryParams}`);
    }
  };

  const handleAssignEvaluatorAuto = async () => {
    if (loadingAssignAuto) return;

    try {
      setLoadingAssignAuto(true);
      const result = await assignEvaluatorAuto(selectedProgramTableOne?.id);

      if (result.message === "No unassigned survey responses found.") {
        toast.error(
          "Il n'y a actuellement aucun candidat disponible pour être assigné automatiquement",
          {
            position: "bottom-right",
            autoClose: 2000,
          }
        );
      } else {
        toast.success("Assignement automatique effectué avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }

      fetchCandidaturesEvaluateur();
    } catch (error) {
      console.error("Error during assignement automatique:", error);
      toast.error("Erreur lors de l'assignement automatique.", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setLoadingAssignAuto(false);
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    const fetchProgramFilters = async () => {
      try {
        const programFilters = await getAllProgramsFilters();
        programFilters?.unshift({
          id: undefined,
          // title: "Appels à candidature",
        });
        setProgramsTableOne(programFilters);

        if (programFilters.length > 1) {
          const latestProgram = programFilters[1];
          setSelectedProgramTableOne(latestProgram);
        }
      } catch (error) {
        console.log("error in fetching program filters list", error);
      }
    };

    fetchProgramFilters();
  }, []);

  useEffect(() => {
    const fetchProgramFilters = async () => {
      try {
        const programFilters = await getAllProgramsFilters();
        programFilters?.unshift({
          id: undefined,
          // title: "Appels à candidature",
        });
        setProgramsTableTwo(programFilters);

        if (programFilters.length > 1) {
          const latestProgram = programFilters[1];
          setSelectedProgramTableTwo(latestProgram);
        }
      } catch (error) {
        console.log("error in fetching program filters list", error);
      }
    };

    fetchProgramFilters();
  }, []);

  const handleProgramChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedProgramTableOne(selectedValue);
  };

  const handleProgramChangeTableTwo = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedProgramTableTwo(selectedValue);
  };

  return (
    <>
      <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-[50%]">
        <h1 className="text-3xl font-bold mb-0">Liste des candidatures</h1>
        <p className="text-gray-500 text-sm mb-4">
          Suivez le statut des candidatures.
        </p>
        <div className="pb-2 flex mb-4"></div>
        <div className="flex justify-between mb-4 gap-4">
          <FormControl
            variant="outlined"
            sx={{ minWidth: 150, maxWidth: "auto" }}
            // key={selectedProgram?.id}
          >
            <Select
              value={selectedProgramTableOne.title || ""}
              onChange={handleProgramChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ChevronDownIcon className="w-7 h-7 text-white mr-3" />
              )}
              renderValue={(selected) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <i className="bi bi-filter text-xl mx-2"></i>
                  <span style={{ flex: 1, textAlign: "center" }}>
                    {selectedProgramTableOne?.title || "Appels à candidature"}
                  </span>
                </div>
              )}
              sx={{
                backgroundColor: "#0976BC",
                color: "white",
                borderRadius: "30px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "30px",
                  paddingRight: "2.5rem",
                },
                "& .MuiSelect-icon": {
                  display: "none",
                },
              }}
            >
              {programsTableOne.map((program, index) => (
                <MenuItem
                  key={program.id || index}
                  value={JSON.stringify({
                    id: program.id,
                    title: program.title,
                  })}
                >
                  {program.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="flex gap-[10px]">
            <Button
              style={{
                textTransform: "none",
              }}
              className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium bg-[#0976BC] hover:bg-[#065A94] text-white transition-colors duration-300`}
              onClick={() => setOpenDialog(true)}
            >
              Assignement automatique
            </Button>

            <Button
              style={{
                textTransform: "none",
                backgroundColor:
                  selectedEvaluator.length > 0 ? "#0976BC" : "#ccc",
                color: selectedEvaluator.length > 0 ? "white" : "#666",
                cursor:
                  selectedEvaluator.length > 0 ? "pointer" : "not-allowed",
              }}
              className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium ${
                selectedEvaluator.length > 0
                  ? "hover:bg-[#065A94] transition-colors duration-300"
                  : ""
              }`}
              disabled={selectedEvaluator.length === 0}
              onClick={handleAssignEvaluatorClick}
            >
              <img
                src={assignCoachIcon}
                alt="assignCoachIcon"
                width={20}
                height={20}
              />
              Assigner un évaluateur
            </Button>
          </div>
        </div>

        <div className="border rounded-lg p-3 bg-white">
          <div className="flex justify-between items-center mb-3">
            <div className="relative w-1/4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
              </div>
              <input
                type="search"
                className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
                placeholder="Rechercher par nom, mail..."
                onChange={handleSearchChangeEvaluator}
              />
            </div>
            <div className="flex gap-[16px] ">
              <FormControl
                variant="outlined"
                sx={{ width: 160, fontFamily: "Arial, sans-serif" }}
              >
                <Select
                  value={statusEvaluator}
                  onChange={handleStatusChangeEvaluator}
                  displayEmpty
                  style={{ "height ": "45px" }}
                  IconComponent={() => (
                    <ChevronDownIcon className="w-7 text-gray mr-3" />
                  )}
                  renderValue={(selected) => {
                    const selectedOption = statusOptions.find(
                      (option) => option.value === selected
                    );
                    return selectedOption ? selectedOption.label : "Status";
                  }}
                  sx={{
                    backgroundColor: "white",
                    color: "#333",
                    borderRadius: "4px",
                    fontFamily: "Arial, sans-serif",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #dee2e6",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "30px",
                      paddingRight: "2.5rem",
                    },
                    "& .MuiSelect-icon": {
                      display: "none",
                    },
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: 180, fontFamily: "Arial, sans-serif" }}
              >
                <Select
                  value={evalActionstatus}
                  onChange={handleEvaluationActionStatus}
                  displayEmpty
                  style={{ "height ": "45px" }}
                  IconComponent={() => (
                    <ChevronDownIcon className="w-7 text-gray mr-3" />
                  )}
                  renderValue={(selected) => {
                    const selectedOption = evalActionStatus.find(
                      (option) => option.value === selected
                    );
                    return selectedOption
                      ? selectedOption.label
                      : "Évaluation status";
                  }}
                  sx={{
                    backgroundColor: "white",
                    color: "#333",
                    borderRadius: "4px",
                    fontFamily: "Arial, sans-serif",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #dee2e6",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "30px",
                      paddingRight: "2.5rem",
                    },
                    "& .MuiSelect-icon": {
                      display: "none",
                    },
                  }}
                >
                  {evalActionStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ExportButtonDropdown Exportstatus={statusEvaluator} />
            </div>
          </div>
          <TableContainer
            className="relative overflow-y-auto rounded-lg border border-gray-300"
            style={{ height: "490px" }}
          >
            <Table>
              <TableHead>
                <TableRow hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                    // checked={
                    //   selected.length > 0 &&
                    //   selected.length === candidatures.length
                    // }
                    // onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>Nom du candidat</TableCell>
                  <TableCell>Adresse mail</TableCell>
                  <TableCell>Appel à candidature</TableCell>
                  <TableCell>Date de soumission</TableCell>
                  <TableCell>État</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingEvaluator ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : candidaturesEvaluator.length > 0 ? (
                  candidaturesEvaluator.map((candidature, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelectedEvaluator(candidature.id)}
                      tabIndex={-1}
                      key={candidature.id}
                      selected={isSelectedEvaluator(candidature.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={
                            candidature.status !== "SOUMIS" ||
                            candidature?.evaluationActions?.some(
                              (elem) => elem?.evaluatorKeyCloakId
                            )
                          }
                          checked={isSelectedEvaluator(candidature.id)}
                          onChange={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleClickEvaluator(
                              event,
                              candidature.id,
                              candidature
                            );
                          }}
                          style={{
                            pointerEvents:
                              candidature.status !== "SOUMIS" ? "none" : "auto",
                            opacity: candidature.status !== "SOUMIS" ? 0.5 : 1,
                            color:
                              candidature.status !== "SOUMIS" ? "#ccc" : "",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {candidature?.user?.firstName}{" "}
                        {candidature?.user?.lastName}
                      </TableCell>
                      <TableCell>{candidature?.user?.email}</TableCell>
                      <TableCell>{candidature.program?.title}</TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>
                            {new Date(
                              candidature?.updatedAt
                            ).toLocaleDateString("fr-FR")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {candidature.percentage !== undefined ? (
                          <span>{candidature?.percentage?.toFixed(2)}%</span>
                        ) : (
                          <span>0%</span>
                        )}
                      </TableCell>

                      <TableCell>
                        <GetStatus status={candidature.status} />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/unite_de_gestion-dashboard/liste-candidatures/details/${candidature.id}`}
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                          style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                        >
                          Voir détails
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Aucune donnée affichée
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCountEvaluator}
            page={pageEvaluator}
            onPageChange={handleChangePageEvaluator}
            rowsPerPage={rowsPerPageEvaluator}
            onRowsPerPageChange={handleChangeRowsPerPageEvaluator}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </div>
      </div>

      <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-[50%]">
        <div className="flex justify-between mb-4">
          <FormControl
            variant="outlined"
            sx={{ minWidth: 150, maxWidth: "auto" }}
            // key={selectedProgram?.id}
          >
            <Select
              value={selectedProgramTableTwo?.title || ""}
              onChange={handleProgramChangeTableTwo}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ChevronDownIcon className="w-7 h-7 text-white mr-3" />
              )}
              renderValue={(selected) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <i className="bi bi-filter text-xl mx-2"></i>
                  <span style={{ flex: 1, textAlign: "center" }}>
                    {selectedProgramTableTwo?.title || "Appels à candidature"}
                  </span>
                </div>
              )}
              sx={{
                backgroundColor: "#0976BC",
                color: "white",
                borderRadius: "30px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "30px",
                  paddingRight: "2.5rem",
                },
                "& .MuiSelect-icon": {
                  display: "none",
                },
              }}
            >
              {programsTableTwo?.map((program, index) => (
                <MenuItem
                  key={program?.id || index}
                  value={JSON.stringify({
                    id: program?.id,
                    title: program?.title,
                  })}
                >
                  {program?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            style={{
              textTransform: "none",
              backgroundColor: selected.length > 0 ? "#0976BC" : "#ccc",
              color: selected.length > 0 ? "white" : "#666",
              cursor: selected.length > 0 ? "pointer" : "not-allowed",
            }}
            className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium ${
              selected.length > 0
                ? "hover:bg-[#065A94] transition-colors duration-300"
                : ""
            }`}
            disabled={selected.length === 0}
            onClick={handleAssignCoachClick}
          >
            <img
              src={assignCoachIcon}
              alt="assignCoachIcon"
              width={20}
              height={20}
            />
            Assigner un coach
          </Button>
        </div>
        <div className="border rounded-lg p-3 bg-white">
          <div className="flex justify-between items-center mb-3">
            <div className="relative w-1/4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
              </div>
              <input
                type="search"
                className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
                placeholder="Rechercher par nom, mail..."
                onChange={handleSearchChange}
              />
            </div>
            <div className="flex gap-[16px] ">
              <FormControl
                variant="outlined"
                sx={{ width: 160, fontFamily: "Arial, sans-serif" }}
              >
                <Select
                  value={status}
                  onChange={handleStatusChange}
                  displayEmpty
                  style={{ "height ": "45px" }}
                  IconComponent={() => (
                    <ChevronDownIcon className="w-7 text-gray mr-3" />
                  )}
                  renderValue={(selected) => {
                    const selectedOption = statusOptions.find(
                      (option) => option.value === selected
                    );
                    return selectedOption ? selectedOption.label : "Status";
                  }}
                  sx={{
                    backgroundColor: "white",
                    color: "#333",
                    borderRadius: "4px",
                    fontFamily: "Arial, sans-serif",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #dee2e6",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "30px",
                      paddingRight: "2.5rem",
                    },
                    "& .MuiSelect-icon": {
                      display: "none",
                    },
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <TableContainer
            className="relative overflow-y-auto rounded-lg border border-gray-300"
            style={{ height: "490px" }}
          >
            <Table>
              <TableHead>
                <TableRow hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                    // checked={
                    //   selected.length > 0 &&
                    //   selected.length === candidatures.length
                    // }
                    // onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>Nom du candidat</TableCell>
                  <TableCell>Adresse mail</TableCell>
                  <TableCell>Appel à candidature</TableCell>
                  <TableCell>Date de soumission</TableCell>
                  <TableCell>État</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : candidatures.length > 0 ? (
                  candidatures.map((candidature, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected(candidature.id)}
                      tabIndex={-1}
                      key={candidature.id}
                      selected={isSelected(candidature.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={
                            candidature.status !== "Sélectionné" ||
                            !!candidature?.keycloakCoachId
                          }
                          checked={
                            candidature.status === "Sélectionné" &&
                            isSelected(candidature.id)
                          }
                          onChange={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleClick(event, candidature.id, candidature);
                          }}
                          style={{
                            pointerEvents:
                              candidature.status !== "Sélectionné"
                                ? "none"
                                : "auto",
                            color:
                              candidature.status !== "Sélectionné" ||
                              !!candidature?.keycloakCoachId
                                ? "#ccc"
                                : "",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {candidature?.user?.firstName}{" "}
                        {candidature?.user?.lastName}
                      </TableCell>
                      <TableCell>{candidature?.user?.email}</TableCell>
                      <TableCell>{candidature.program?.title}</TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>
                            {new Date(
                              candidature?.updatedAt
                            ).toLocaleDateString("fr-FR")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {candidature.percentage !== undefined ? (
                          <span>{candidature?.percentage?.toFixed(2)}%</span>
                        ) : (
                          <span>0%</span>
                        )}
                      </TableCell>

                      <TableCell>
                        <GetStatus status={candidature.status} />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/unite_de_gestion-dashboard/liste-candidatures/details/${candidature.id}`}
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                          style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                        >
                          Voir détails
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Aucune donnée affichée
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </div>
      </div>
      <ToastContainer />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          {"Êtes-vous certain d'affecter toutes les candidatures éligibles ?"}
        </DialogTitle>
        <DialogContent className="text-center">
          Cette action est irréversible.
        </DialogContent>
        <DialogActions className="m-auto pb-8">
          <Button
            onClick={() => setOpenDialog(false)}
            variant="outlined"
            className="border-[#0976BC] text-[#0976BC] hover:bg-blue-100 rounded-full"
            style={{
              padding: "8px 24px",
              borderWidth: "2px",
              textTransform: "none",
            }}
          >
            Annuler
          </Button>
          <Button
            style={{
              textTransform: "none",
              backgroundColor: loadingAssignAuto ? "#ccc" : "#0976BC",
              color: loadingAssignAuto ? "#666" : "white",
              cursor: loadingAssignAuto ? "not-allowed" : "pointer",
              padding: "8px 24px",
              borderWidth: "2px",
            }}
            className={` flex items-center rounded-full font-medium ${
              !loadingAssignAuto
                ? "hover:bg-[#065A94] transition-colors duration-300"
                : ""
            }`}
            onClick={handleAssignEvaluatorAuto}
            disabled={loadingAssignAuto}
          >
            {loadingAssignAuto ? (
              <span>Assignation...</span>
            ) : (
              <span>Confirmer</span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListeCandidatures;
