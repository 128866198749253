import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../contexts/AuthContext";
import {
  evaluateCandidate,
  evaluationApprofondie,
} from "../../../../services/evaluation.service";
import { useForm } from "react-hook-form";
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import { Model, Survey } from "survey-react-ui";
import { PlainLight } from "survey-core/themes";

const EvaluationProcess = ({ id, evaluationForm, evaluationFormResponses }) => {
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [surveyModel, setSurveyModel] = useState(null);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [totalScore, setTotalScore] = useState(null);

  const fetchEvaluationForm = async () => {
    try {
      const surveyInstance = new Model(evaluationForm);
      surveyInstance.applyTheme(PlainLight);
      surveyInstance.locale = "fr";
      surveyInstance.completeText = "Soumettre";
      surveyInstance.previewText = "Aperçu et soumettre";
      surveyInstance.showCompletedPage = false;
      surveyInstance.showTitle = false;
      if (evaluationFormResponses) {
        surveyInstance.data = evaluationFormResponses;
      }

      const pageTitles = surveyInstance.pages.map(
        (page) => page.title || `Page ${page.name}`
      );
      pageTitles.push("Commentaire"); // Final step
      setSteps(pageTitles);

      // Sync Stepper with survey page navigation
      surveyInstance.onCurrentPageChanged.add((sender) => {
        setActiveStep(sender.currentPageNo);
      });

      // Handle Survey Completion with improved error handling
      surveyInstance.onComplete.add(async (sender) => {
        setIsSubmitting(true);

        try {
          const response = await evaluationApprofondie(id, {
            evaluationApprofondieResponses: sender.data,
          });
          setTotalScore(sender.data?.total_score);
          setSurveyCompleted(true);
          surveyInstance.showCompletedPage = true;

          surveyInstance.completedHtml = `
          <div style="text-align: center; font-size: 18px; color: green;">
            <h3>Merci d'avoir complété cette évaluation !</h3>
            <p>La note finale est : <strong>${sender.data?.total_score}%</strong></p>
          </div>
        `;

          toast.success(
            `Votre réponse est sauvegardée avec succès et le score final est ${sender.data?.total_score}%.`,
            {
              position: "bottom-right",
              autoClose: 2000,
              transition: Bounce,
            }
          );
        } catch (error) {
          console.error("Error submitting evaluation response", error);

          surveyInstance.completedHtml = "";

          toast.error(
            "Erreur lors du sauvegarde des réponses d'évaluation. Veuillez réessayer.",
            {
              position: "bottom-right",
              autoClose: 2000,
              transition: Bounce,
            }
          );

          setSurveyCompleted(false);
          setActiveStep(0);
          surveyInstance.currentPageNo = 0;

          surveyInstance.clear(false);
          if (evaluationFormResponses) {
            surveyInstance.data = evaluationFormResponses;
          }
        } finally {
          setIsSubmitting(false);
        }
      });
      setSurveyModel(surveyInstance);
    } catch (error) {
      console.error("Error fetching evaluation survey", error);
      toast.error("Erreur lors de récupération du formulaire d'évaluation.", {
        position: "bottom-right",
        autoClose: 2000,
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    fetchEvaluationForm();
  }, []);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      if (activeStep < surveyModel.pages.length - 1) {
        surveyModel.nextPage(); // Move to the next survey page
      } else {
        if (!surveyCompleted) {
          toast.error(
            "Veuillez soumettre votre formulaire avant de continuer.",
            {
              position: "bottom-right",
              autoClose: 2000,
              transition: Bounce,
            }
          );
          return;
        }
        setActiveStep((prev) => prev + 1); // Move to final step
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      if (activeStep < surveyModel.pages.length) {
        surveyModel.prevPage(); // Move to the previous survey page
      } else {
        setActiveStep((prev) => prev - 1);
      }
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await evaluateCandidate(id, userId, {
        status: totalScore >= 50 ? "Éligible" : "Rejeté",
        evaluatorComment: data?.comment,
      });
      if (totalScore >= 50) {
        toast.success(
          `La note finale de la candidature est ${totalScore}, Cette candidature est évaluée avec un statut "Éligible"`,
          {
            position: "bottom-right",
            autoClose: 2000,
            transition: Bounce,
            onClose: () => navigate(-1),
          }
        );
      } else {
        toast.error(
          `La note finale de la candidature est ${totalScore}, Cette candidature est évaluée avec un statut "Rejeté"`,
          {
            position: "bottom-right",
            autoClose: 2000,
            transition: Bounce,
            onClose: () => navigate(-1),
          }
        );
      }
    } catch (error) {
      toast.error("Erreur lors du sauvegarde de l'évaluation.", {
        position: "bottom-right",
        autoClose: 2000,
        transition: Bounce,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-4">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Survey Form with Stepper Control */}
      {activeStep < steps.length - 1 && (
        <div className="mt-4">
          {surveyModel && <Survey model={surveyModel} />}
          <div className="flex justify-between mt-4">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={surveyCompleted || activeStep === 0}
              sx={{ textTransform: "none" }}
            >
              Retour
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{ textTransform: "none" }}
            >
              {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
            </Button>
          </div>
        </div>
      )}

      {/* Step 3: Comments & Validation */}
      {activeStep === steps.length - 1 && (
        <div className="mt-4">
          <label className="font-semibold text-[#0976BC] text-lg">
            Veuillez ajouter votre commentaire:
          </label>
          <textarea
            rows="4"
            cols="50"
            placeholder="Ajoutez un commentaire..."
            className="p-2 w-full border rounded-lg"
            {...register("comment")}
          ></textarea>

          <div className="flex justify-between mt-4">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              sx={{ textTransform: "none" }}
            >
              Retour
            </Button>

            <div className="flex gap-2">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit((data) => onSubmit(data))}
                disabled={isSubmitting}
                startIcon={<CheckCircleIcon className="h-5 w-5" />}
                sx={{ textTransform: "none" }}
              >
                {isSubmitting ? "En cours..." : "Valider"}
              </Button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default EvaluationProcess;
