import axiosInstance from "../helpers/axiosIntercepter";

export const remindSignConvention = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/jurdique-form/remind-sign-convention/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("error reminding to sign convention", error);
    throw error;
  }
};

export const signConvention = async (id, signatureUrl, parapheUrl,missingInfo,luApprove) => {
  try {
    const response = await axiosInstance.post(
      `/survey-response/signature-laureat/${id}`,
      {
        signatureUrl,
        parapheUrl,
        missingInfo,
        luApprove
      }
    );
    return response.data;
  } catch (error) {
    console.error("error reminding to sign convention", error);
    throw error;
  } 
};

export const signatureCoordinateur = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/survey-response/signature-coordinateur",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Failed to send signature data:", error.response?.data || error.message);
    throw error;
  }
};



export const getMontantSubvention = async (id) => {
  try {
    const response = await axiosInstance.get(
      "/coach/motant-subvention/" + id,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to send signature data:", error.response?.data || error.message);
    throw error;
  }
};



export const updateMontantSubvention = async (id,data) => {
  try {
    const response = await axiosInstance.patch(
      "/coach/motant-subvention/" + id,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Failed to send signature data:", error.response?.data || error.message);
    throw error;
  }
};


